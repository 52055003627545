// @ts-strict-ignore
import { Box } from '@mui/material';
import { css, styled } from '@mui/material/styles';
import {
  trackDropdownInputUsageAnalyticsEvent,
  trackMultiSelectionInputUsageAnalyticsEvent
} from 'analytics/events/input-usage';

import { find, findIndex, isArray } from 'lodash/fp';

import { observer } from 'mobx-react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';

import { ActionMeta } from 'react-select/dist/declarations/src/types';

import { useStores } from 'mobx/hooks/useStores';

import { TicketSubTypeOption } from 'utils/TicketType.utils';

import Patient from 'models/Patient';
import { TicketTypeKind } from 'models/TicketTypes';

import OperatorCreateTicketInfoFormItem from 'components/Ticket/TicketForms/OperatorCreateTicketInfoFormItem';
import { isSymptomTicket } from 'components/Ticket/TicketForms/OperatorEditTicketInfoForm';
import { TicketFormField } from 'components/Ticket/TicketForms/ticket.shared';
import { FormTicketTypeMultiAutocomplete } from 'components/UIkit/atoms/Dropdown';
import { SelectActionMetaName } from 'components/UIkit/atoms/Dropdown/SelectUtils';
import { Text } from 'components/UIkit/atoms/Text';

import {
  defaultNonSymptomUrgencyValue,
  getUrgencyDefaultValue,
  ticketTypesSelectCustomFilter,
  useTicketTypesOptions
} from './TicketsInfoFormFragments/OperatorTicketsInfoFormCommon';

export const OperatorTicketsInfoForm = observer(({ patient }: { patient: Patient }) => {
  const { control, formState } = useFormContext();
  const tickets = useWatch({ name: 'tickets', control });

  const {
    fields: selectedTicketFields,
    remove,
    append,
    update
  } = useFieldArray({
    control,
    name: 'tickets',
    shouldUnregister: true
  });

  const { errors } = formState;
  const { ticketTypesStore } = useStores();

  const groupedTicketTypesOptions = useTicketTypesOptions({
    // filter deleted or not allowed types
    filterFn: (node) => node.isActive && node.reportableInAll && !node.isFever,
    showFullNameForNonSymptomTicketTypes: true
  });

  const handleSymptomTicketTypeRemove = (removedTicketType: TicketSubTypeOption) => {
    const existingTicketFormField = find<TicketFormField>(
      (item) => item.parentId === removedTicketType.parentId,
      tickets
    );

    const isMultiSymptomsTicket =
      existingTicketFormField &&
      (existingTicketFormField?.ticketTypeSelectorValue as TicketSubTypeOption[]).length > 1;

    if (isMultiSymptomsTicket) {
      const existingSymptomFieldIndex = findIndex(existingTicketFormField, tickets);

      update(existingSymptomFieldIndex, {
        ...existingTicketFormField,
        ticketSubTypeIds: existingTicketFormField.ticketSubTypeIds.filter(
          (id) => id !== removedTicketType.value
        ),
        ticketTypeSelectorValue: (
          existingTicketFormField.ticketTypeSelectorValue as TicketSubTypeOption[]
        ).filter((selector) => selector.value !== removedTicketType.value)
      });

      return;
    }

    const indexToRemove = tickets.findIndex(
      (ticket: TicketFormField) =>
        isArray(ticket.ticketTypeSelectorValue) || isSymptomTicket(ticket, ticketTypesStore) //if ticketTypeSelectorValue is an array -> this is the symptoms ticket
    );

    remove(indexToRemove);
  };

  const handleTicketTypeRemove = (
    selectedSubTypesOptions: TicketSubTypeOption[],
    actionMeta: ActionMeta<TicketSubTypeOption>
  ) => {
    if (!selectedSubTypesOptions) {
      update(0, {
        ...selectedTicketFields[0],
        urgency: defaultNonSymptomUrgencyValue,
        notes: '',
        assignee: null,
        categoryId: '',
        ticketSubTypeIds: [],
        parentId: null,
        ticketTypeSelectorValue: null
      });
      return;
    }

    const removedTicketType = actionMeta.removedValue;

    const isSymptomTicketType = removedTicketType.parentKind === TicketTypeKind.symptom;

    if (isSymptomTicketType) {
      handleSymptomTicketTypeRemove(removedTicketType);
    } else {
      //in case we don't have a ticket type, we want to render the default empty one
      const indexToRemove = tickets.findIndex(
        (ticket: TicketFormField) => ticket.ticketSubTypeIds[0] === removedTicketType.value
      );
      remove(indexToRemove);
    }
  };

  const handleTicketTypeSelect = (
    selectedSubTypesOptions: TicketSubTypeOption[],
    actionMeta: ActionMeta<TicketSubTypeOption>
  ) => {
    const newTicketType = actionMeta.option;
    const categoryNode = ticketTypesStore.getCategoryByParentId(newTicketType.parentId);
    const parentNode = ticketTypesStore.getTicketTypeByKind(
      newTicketType.parentId,
      newTicketType.parentKind as TicketTypeKind
    );

    const isSymptomTicketType = newTicketType.parentKind === TicketTypeKind.symptom;
    const isFirstTicket = selectedSubTypesOptions.length === 1;

    const newDefaultTicketType: Partial<TicketFormField> = {
      urgency: getUrgencyDefaultValue(isSymptomTicketType),
      notes: '',
      categoryId: String(categoryNode.id),
      ticketSubTypeIds: [newTicketType.value],
      parentId: newTicketType.parentId,
      ticketTypeSelectorValue: isSymptomTicketType ? [newTicketType] : newTicketType,
      assignee: null
    };

    const newFirstDefaultTicketType: Partial<TicketFormField> = {
      ...selectedTicketFields[0],
      urgency: getUrgencyDefaultValue(isSymptomTicketType),
      notes: tickets[0].notes,
      categoryId: String(categoryNode.id),
      ticketSubTypeIds: [newTicketType.value],
      parentId: newTicketType.parentId,
      ticketTypeSelectorValue: isSymptomTicketType ? [newTicketType] : newTicketType
    };

    if (isFirstTicket) {
      update(0, newFirstDefaultTicketType);
      return;
    }

    // For symptom management we need to check for existing form fields
    const existingTicketFormField = find<TicketFormField>(
      (item) => item.parentId === newTicketType.parentId,
      tickets
    );

    // If it's symptom management we need to update form field not add a new one
    if (existingTicketFormField && parentNode.kind === TicketTypeKind.symptom) {
      const existingSymptomFieldIndex = findIndex(existingTicketFormField, tickets);

      const newTicketTypeSelectorValue = isArray(existingTicketFormField.ticketTypeSelectorValue)
        ? [
            ...(existingTicketFormField.ticketTypeSelectorValue as TicketSubTypeOption[]),
            newTicketType
          ]
        : [existingTicketFormField.ticketTypeSelectorValue, newTicketType];

      update(existingSymptomFieldIndex, {
        ...existingTicketFormField,
        ticketSubTypeIds: [...existingTicketFormField.ticketSubTypeIds, newTicketType.value],
        ticketTypeSelectorValue: newTicketTypeSelectorValue
      });
    } else {
      // either first of type symptom management or just a non-symptom operator type
      // Adding default values to new type
      append(newDefaultTicketType);
    }
  };

  const onTicketTypesChange = (
    selectedSubTypesOptions: TicketSubTypeOption[],
    actionMeta: ActionMeta<TicketSubTypeOption>,
    eventKey: string | null
  ) => {
    const { action } = actionMeta;
    trackMultiSelectionInputUsageAnalyticsEvent(
      actionMeta,
      'Ticket Types Main Dropdown',
      eventKey === 'Enter'
    );

    if (action === SelectActionMetaName.Select) {
      handleTicketTypeSelect(selectedSubTypesOptions, actionMeta);
    }

    if (action === SelectActionMetaName.Remove) {
      handleTicketTypeRemove(selectedSubTypesOptions, actionMeta);
    }
  };

  const generateUniqueKey = (selectedTicketType: TicketFormField) => {
    const { parentId, ticketSubTypeIds } = selectedTicketType;
    const subTypesKey = ticketSubTypeIds?.length ? ticketSubTypeIds.join('_') : '_';
    return `${parentId}_${subTypesKey}`;
  };

  return (
    <Box display="flex" flexDirection="column" gap={20}>
      <StyledCreateTicketGridItem p={20}>
        <Box flex={1}>
          <Box pb={20}>
            <Text variant="h4" component="h4">
              Which tickets would you like to create?
            </Text>
            <Text variant="subtext" color="secondary" component="div">
              You can select more than one ticket type to create multiple tickets during a single
              call.
            </Text>
          </Box>
          <FormTicketTypeMultiAutocomplete
            warnOnRemove
            name="ticketTypes"
            placeholder="Select Ticket Types"
            aria-label="Add Ticket Types"
            options={groupedTicketTypesOptions}
            onChange={onTicketTypesChange}
            backspaceRemovesValue={false}
            isClearable={false}
            filterOption={ticketTypesSelectCustomFilter}
            testHook="ticketTypes"
          />
        </Box>
      </StyledCreateTicketGridItem>

      <Box>
        {selectedTicketFields.map((selectedTicketType, index) => {
          // @ts-ignore
          const internalId = generateUniqueKey(selectedTicketType);
          const { id } = selectedTicketType;
          const fieldName = `tickets[${index}]`;
          // @ts-ignore
          const ticketError = errors.tickets && errors.tickets[id] ? errors.tickets[id] : {};

          return (
            <OperatorCreateTicketInfoFormItem
              key={id}
              testHook={`operator-create-ticket-item-${internalId}`}
              fieldName={fieldName}
              errors={ticketError}
              patient={patient}
              // @ts-ignore
              categoryId={selectedTicketType.categoryId}
              // @ts-ignore
              ticketSubTypeIds={selectedTicketType.ticketSubTypeIds}
              onDropdownChange={trackDropdownInputUsageAnalyticsEvent}
              removeDynamicFormTickets={remove}
              updateDynamicFormTickets={update}
            />
          );
        })}
      </Box>
    </Box>
  );
});

const StyledCreateTicketGridItem = styled(Box)(
  ({ theme }) =>
    css`
      display: flex;
      border: 1px solid ${theme.palette.natural.border};
      border-radius: ${theme.borderRadius.medium};
      transition: border-color 0.2s ease-in-out;
      background-color: ${theme.palette.natural.white};
      color: ${theme.palette.secondary.dark};
    `
);
