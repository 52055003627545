import { FC, ReactNode, useCallback, useEffect, useState, useRef } from 'react';

import { Box, useTheme } from '@mui/material';
import { css, styled } from '@mui/material/styles';
import { AnalyticEventAction } from 'analytics';
import { trackActionButtonAnalyticsEvent } from 'analytics/events/action-button';
import { trackMessageSuggestionSelectAnalyticsEvent } from 'analytics/events/message-suggestion-select';
import { trackMessageSuggestionsLoadedAnalyticsEvent } from 'analytics/events/message-suggestions';
import { trackSendSmsAnalyticsEvent } from 'analytics/events/send-sms';

import { observer } from 'mobx-react';

import { FormProvider, useForm, useWatch } from 'react-hook-form';

import { ticketMessagesSectionSelectors } from 'tests/models/components/ticket-messages-section/ticket-messages-section.selectors';

import { useStores } from 'mobx/hooks/useStores';

import {
  TwoWayMessagesFetcher,
  AiMessageSuggestion,
  SendMessageToPatientBody
} from 'fetchers/TwoWayMessagesFetcher';

import { showToast } from 'utils/UserMessageUtils';

import Patient, { IPatientContact, PatientType } from 'models/Patient';

import { PhoneType } from 'models/PhoneNumberDetails';

import Ticket from 'models/Ticket';

import FormTextAreaField from 'components/Forms/FormTextAreaField';

import Icon from 'components/Icons/Icon';
import { usePatientModel } from 'components/Patient/usePatientModel';
import { AiSuggestions } from 'components/Ticket/TicketRow/TicketCommunicationSection/TicketMessages/AiSuggestions/AiSuggestions';
import { TicketClinicianMessage } from 'components/Ticket/TicketRow/TicketCommunicationSection/TicketMessages/TicketClinicianMessage/TicketClinicianMessage';
import {
  TicketMessage,
  TicketMessageType
} from 'components/Ticket/TicketRow/TicketCommunicationSection/TicketMessages/TicketMessages.types';
import { getTicketMessagesContactFormattedName } from 'components/Ticket/TicketRow/TicketCommunicationSection/TicketMessages/TicketMessages.utils';
import { TicketPatientMessage } from 'components/Ticket/TicketRow/TicketCommunicationSection/TicketMessages/TicketPatientMessage/TicketPatientMessage';
import { TicketPatientContactType } from 'components/Ticket/TicketRow/TicketContact/TicketContact.types';
import { Tooltip } from 'components/Tooltip';
import { Text } from 'components/UIkit/atoms/Text';

interface Props {
  ticket: Ticket;
  ticketIndex: number;
  ticketSectionCurrentPage: number;
}

interface SendMessageForm {
  message: string;
}

const TEXT_HEIGHT_OFFSET = 130;
const SEND_ICON_DIM = 20;

export const TicketMessagesWithSuggestions: FC<Props> = observer(
  ({ ticket, ticketIndex, ticketSectionCurrentPage }) => {
    const { ticketsStore } = useStores();
    const [ticketMessages, setTicketMessages] = useState<TicketMessage[]>(ticket.messages || []);
    const [retryingMessageIds, setRetryingMessageIds] = useState<number[]>([]);
    const [isSendingMessage, setIsSendingMessage] = useState(false);
    const [isLoadingMessages, setIsLoadingMessages] = useState(true);
    const [selectedSuggestionId, setSelectedSuggestionId] = useState<string | null>(null);
    const [originalSuggestionText, setOriginalSuggestionText] = useState<string | null>(null);
    const [isInputFocused, setIsInputFocused] = useState(false);
    const [shouldShowSuggestions, setShouldShowSuggestions] = useState<boolean>(true);
    const [suggestions, setSuggestions] = useState<AiMessageSuggestion[]>([]);
    const [isLoadingSuggestions, setIsLoadingSuggestions] = useState(true);
    const [suggestionsError, setSuggestionsError] = useState(false);
    const [currentSuggestionBatchId, setCurrentSuggestionBatchId] = useState<string | null>(null);
    const currentSuggestionBatchLoadedRef = useRef<boolean>(false);
    const [previewSuggestionText, setPreviewSuggestionText] = useState<string | null>(null);
    const isInitializedRef = useRef(false);
    const previousMessagesCountRef = useRef(0);
    const suggestionsRef = useRef<HTMLDivElement | null>(null);
    const [suggestionsHeight, setSuggestionsHeight] = useState<number>(0);

    const patient = usePatientModel(ticket.patientId);
    const { palette } = useTheme();
    const sendMessageForm = useForm<SendMessageForm>({
      defaultValues: {
        message: ''
      }
    });

    const messageText = useWatch({
      control: sendMessageForm.control,
      name: 'message'
    });

    useEffect(
      function clearSelectionOnMessageChange() {
        if (originalSuggestionText) {
          if (messageText === originalSuggestionText) {
            if (!selectedSuggestionId) {
              const matchingSuggestion = suggestions.find(
                (suggestion) => suggestion.suggestionText === originalSuggestionText
              );
              if (matchingSuggestion) {
                setSelectedSuggestionId(matchingSuggestion.uuid);
              }
            }
          } else if (selectedSuggestionId) {
            setSelectedSuggestionId(null);
          }
        }
      },
      [messageText, selectedSuggestionId, originalSuggestionText, suggestions]
    );

    useEffect(
      function resetSelectionOnMessageClear() {
        if (!messageText) {
          setSelectedSuggestionId(null);
          setOriginalSuggestionText(null);
        }
      },
      [messageText]
    );

    useEffect(
      function showSuggestionsBasedOnText() {
        if (messageText && messageText.trim().length > 0) {
          const isShowingSelectedSuggestion =
            Boolean(selectedSuggestionId) && originalSuggestionText === messageText;
          setShouldShowSuggestions(messageText.trim().length === 0 || isShowingSelectedSuggestion);
        } else {
          setShouldShowSuggestions(true);
        }
      },
      [messageText, selectedSuggestionId, originalSuggestionText]
    );

    useEffect(
      function trackLoadedSuggestionsEffect() {
        if (
          isInputFocused &&
          !currentSuggestionBatchLoadedRef.current &&
          currentSuggestionBatchId
        ) {
          currentSuggestionBatchLoadedRef.current = true;

          trackMessageSuggestionsLoadedAnalyticsEvent({
            suggestion_id: currentSuggestionBatchId
          });
        }
      },
      [currentSuggestionBatchId, isInputFocused, ticket.id]
    );

    useEffect(
      function calculateSuggestionsHeightEffect() {
        if ((shouldShowSuggestions && isInputFocused) || isLoadingSuggestions) {
          if (suggestionsRef.current) {
            const height = suggestionsRef.current.offsetHeight;
            setSuggestionsHeight(height);
          }
        }
      },
      [shouldShowSuggestions, isInputFocused, isLoadingSuggestions, suggestions]
    );

    const handleInputFocus = () => {
      setIsInputFocused(true);
    };

    const handleInputBlur = () => {
      setIsInputFocused(false);
      setPreviewSuggestionText(null);
    };

    const fetchTicketMessages = useCallback(
      async function fetchTicketMessages() {
        setIsLoadingMessages(true);
        try {
          const messages = await ticketsStore.fetchMessagesForTicket(ticket);
          setTicketMessages(messages);
          return messages;
        } finally {
          setIsLoadingMessages(false);
        }
      },
      [ticket, ticketsStore]
    );

    const fetchAiSuggestions = useCallback(
      async function fetchAiSuggestions() {
        setIsLoadingSuggestions(true);
        setSuggestionsError(false);
        try {
          const suggestionResponse = await TwoWayMessagesFetcher.getMessageSuggestions(ticket.id);
          const { messageSuggestions: fetchedSuggestions, suggestionId } = suggestionResponse;
          setSuggestions(fetchedSuggestions);
          setCurrentSuggestionBatchId(suggestionId);
          return fetchedSuggestions;
        } catch (error) {
          setSuggestionsError(true);
          return [];
        } finally {
          setIsLoadingSuggestions(false);
        }
      },
      [ticket.id]
    );

    const shouldFetchSuggestions = useCallback((messages: TicketMessage[]) => {
      return !isInitializedRef.current || messages.length > previousMessagesCountRef.current;
    }, []);

    useEffect(
      function init() {
        async function initMessages() {
          const messages = await fetchTicketMessages();

          if (shouldFetchSuggestions(messages)) {
            fetchAiSuggestions();
          }

          previousMessagesCountRef.current = messages.length;

          const unseenMessageIds = messages
            .filter(
              (message) =>
                (message.senderType === TicketMessageType.Patient ||
                  message.senderType === TicketMessageType.Contact) &&
                !message.isSeen
            )
            .map((message) => message.id);

          if (unseenMessageIds.length) {
            await TwoWayMessagesFetcher.markTicketMessagesAsSeen({
              ids: unseenMessageIds
            });
          }
          ticket.clearTicketNewMessagesCount();

          isInitializedRef.current = true;
        }

        initMessages();
      },
      [ticket, fetchTicketMessages, fetchAiSuggestions, shouldFetchSuggestions]
    );

    async function sendTicketMessage(formValue: SendMessageForm) {
      try {
        setIsSendingMessage(true);

        const messagePayload: SendMessageToPatientBody = {
          message: formValue.message,
          ticketId: ticket.id
        };

        if (currentSuggestionBatchId !== null) {
          messagePayload.suggestion = {
            suggestionId: currentSuggestionBatchId
          };

          if (originalSuggestionText) {
            const selectedSuggestion = suggestions.find(
              (suggestion) => suggestion.suggestionText === originalSuggestionText
            );

            if (selectedSuggestion) {
              messagePayload.suggestion.suggestionSelectedUuid = selectedSuggestion.uuid;
              messagePayload.suggestion.isEdited = originalSuggestionText !== formValue.message;
            }
          }
        }

        trackSendSmsAnalyticsEvent({
          action: AnalyticEventAction.Send,
          type: '2 Way SMS',
          is_using_ai_suggestions: Boolean(messagePayload.suggestion?.suggestionSelectedUuid),
          is_manually_edited: Boolean(messagePayload.suggestion?.isEdited)
        });

        await TwoWayMessagesFetcher.sendMessageToPatient(messagePayload);
        showToast({
          message: `Sending Message to ${ticketMessageContactFormattedName}...`
        });

        setSelectedSuggestionId(null);
        setOriginalSuggestionText(null);
        setPreviewSuggestionText(null);
        setIsInputFocused(false);
        sendMessageForm.reset();

        await fetchTicketMessages();
        await fetchAiSuggestions();
      } finally {
        setIsSendingMessage(false);
      }
    }

    async function retryMessage(messageId: number) {
      trackActionButtonAnalyticsEvent({
        action: AnalyticEventAction.TryAgain,
        ticket_id: ticket.id,
        patient_id: ticket.patientId,
        item_index: ticketIndex + 1,
        page_number: ticketSectionCurrentPage + 1
      });

      try {
        setRetryingMessageIds([...retryingMessageIds, messageId]);
        await TwoWayMessagesFetcher.retryTicketMessage(messageId);
      } finally {
        setRetryingMessageIds(retryingMessageIds.filter((id) => id !== messageId));
        await fetchTicketMessages();
      }
    }

    function getSendMessageInputDisabledDetails(): {
      disabledText: string;
      tooltipText?: string | ReactNode;
    } {
      if (ticket.isResolved) {
        return { disabledText: 'Ticket Resolved' };
      }

      if (!patient?.hasMessagingConsent && patient?.type === PatientType.Regular) {
        return {
          disabledText: 'Communication Consent Not Provided',
          tooltipText:
            "To send a message, denote the patient's consent via Create Ticket page, or in the Homecare Instructions section of the Call Logger."
        };
      }

      if (patient?.openConversationTicketId && ticket.id !== patient?.openConversationTicketId) {
        return {
          disabledText: 'Patient already has an active conversation in another ticket.',
          tooltipText:
            'To send a message here, resolve the other ticket from which this patient is currently being messaged.'
        };
      }

      if (
        (contact.type === TicketPatientContactType.Self && Boolean(contact.model.isSMSDisabled)) ||
        (contact.type === TicketPatientContactType.Contact && Boolean(contact.model.smsOptedOutAt))
      ) {
        return {
          disabledText: 'Cannot Message - Opted Out of SMS',
          tooltipText: (
            <>
              This occurs when somebody has replied STOP to an SMS message from Canopy.
              <br />
              <br />
              To opt back in, this patient / Callback Contact must text START to that same number.
            </>
          )
        };
      }

      if (Boolean(contact.model.phoneType === PhoneType.landline)) {
        return {
          disabledText: 'Cannot Message Landline',
          tooltipText:
            'Landline phone numbers are not supported, as message notifications arrive via SMS.'
        };
      }

      return { disabledText: '' };
    }

    function getContact():
      | { type: TicketPatientContactType.Contact; model: IPatientContact }
      | { type: TicketPatientContactType.Self; model: Patient } {
      if (ticket.patientHasContact) {
        const patientContact = patient?.getContactById(ticket.operatorTicket!.patientContactId);

        if (patientContact) {
          return { type: TicketPatientContactType.Contact, model: patientContact };
        }
      }

      return { type: TicketPatientContactType.Self, model: patient! };
    }

    const handleSuggestionSelect = (suggestion: AiMessageSuggestion) => {
      trackMessageSuggestionSelectAnalyticsEvent({
        suggestion_id: currentSuggestionBatchId,
        suggestion_uuid: suggestion.uuid,
        value: suggestion.suggestionType
      });

      setPreviewSuggestionText(null);

      sendMessageForm.setValue('message', suggestion.suggestionText, { shouldTouch: false });
      setOriginalSuggestionText(suggestion.suggestionText);
      setSelectedSuggestionId(suggestion.uuid);
    };

    const handleSuggestionHover = (suggestion: AiMessageSuggestion) => {
      if (!messageText || messageText.trim() === '') {
        setPreviewSuggestionText(suggestion.suggestionText);
      } else if (selectedSuggestionId && originalSuggestionText === messageText) {
        setPreviewSuggestionText(suggestion.suggestionText);
      } else {
        setPreviewSuggestionText(null);
      }
    };

    const handleSuggestionLeave = () => {
      setPreviewSuggestionText(null);
    };

    const contact = getContact();
    const ticketMessageContactFormattedName = getTicketMessagesContactFormattedName(contact);
    const { disabledText, tooltipText } = getSendMessageInputDisabledDetails();
    const isDisabled = Boolean(disabledText) || isSendingMessage;
    const hasSuggestions =
      !isDisabled && isInputFocused && shouldShowSuggestions && !isLoadingMessages;

    return (
      <StyledContainer>
        <Box display="flex" alignItems="center" gap={8} mb={20}>
          <Text color="secondary">PATIENT MESSAGES</Text>

          <Tooltip
            label={<Icon.Info display="flex" color={palette.text.secondary} />}
            maxWidth={342}
          >
            <Box p={20}>
              <StyledInfoList>
                <li>Message is sent via SMS to the ticket's Callback Contact.</li>
                <li>Patient may reply until the ticket is resolved.</li>
              </StyledInfoList>
            </Box>
          </Tooltip>
        </Box>

        <StyledTicketMessagesContainer>
          {ticketMessages.map((message) => (
            <Box key={message.id} pb={20}>
              {message.senderType === TicketMessageType.Clinician && (
                <TicketClinicianMessage
                  message={message}
                  onRetryMessage={retryMessage}
                  isLoading={retryingMessageIds.includes(message.id)}
                />
              )}

              {(message.senderType === TicketMessageType.Patient ||
                message.senderType === TicketMessageType.Contact) && (
                <TicketPatientMessage message={message} />
              )}
            </Box>
          ))}
        </StyledTicketMessagesContainer>

        <FormProvider {...sendMessageForm}>
          <StyledInputContainer>
            <Tooltip
              disabled={!Boolean(tooltipText)}
              maxWidth={342}
              placement="bottom"
              label={
                <StyledFormTextAreaField
                  name="message"
                  rounded
                  disabled={isDisabled}
                  testHook={ticketMessagesSectionSelectors.messageInput}
                  placeholder={
                    previewSuggestionText
                      ? '' // Empty placeholder when preview is shown
                      : disabledText
                      ? disabledText
                      : `SMS to ${ticketMessageContactFormattedName}`
                  }
                  previewText={previewSuggestionText}
                  hasSuggestions={hasSuggestions}
                  isLoadingSuggestions={isLoadingSuggestions}
                  suggestionsHeight={suggestionsHeight}
                  isSending={isSendingMessage}
                  onFocus={handleInputFocus}
                  onBlur={handleInputBlur}
                >
                  {previewSuggestionText && (
                    <StyledPreviewOverlay
                      hasSuggestions={
                        !isDisabled && isInputFocused && shouldShowSuggestions && !isLoadingMessages
                      }
                    >
                      {previewSuggestionText}
                    </StyledPreviewOverlay>
                  )}

                  <StyledInputAction
                    hasSuggestions={hasSuggestions}
                    suggestionHeight={suggestionsHeight}
                  >
                    {Boolean(tooltipText) ? (
                      <Icon.Info color={palette.secondary.dark} />
                    ) : (
                      <StyledSendIcon
                        height={20}
                        width={20}
                        onClick={() =>
                          isSendingMessage
                            ? null
                            : sendMessageForm.handleSubmit(sendTicketMessage)()
                        }
                        color={palette.natural.black}
                        onMouseDown={(e) => e.preventDefault()}
                        data-test-hook={ticketMessagesSectionSelectors.sendButton}
                      />
                    )}
                  </StyledInputAction>

                  {!isDisabled && isInputFocused && shouldShowSuggestions && (
                    <StyledSuggestionsContainer
                      data-test-hook={ticketMessagesSectionSelectors.container}
                    >
                      <AiSuggestions
                        ref={suggestionsRef}
                        onSuggestionSelect={handleSuggestionSelect}
                        onSuggestionHover={handleSuggestionHover}
                        onSuggestionLeave={handleSuggestionLeave}
                        selectedSuggestionId={selectedSuggestionId}
                        currentMessageText={messageText}
                        suggestions={suggestions}
                        isLoading={isLoadingSuggestions || isLoadingMessages}
                        hasError={suggestionsError}
                        onTryAgain={fetchAiSuggestions}
                      />
                    </StyledSuggestionsContainer>
                  )}
                </StyledFormTextAreaField>
              }
            >
              <Box p={20}>{tooltipText}</Box>
            </Tooltip>
          </StyledInputContainer>
        </FormProvider>
      </StyledContainer>
    );
  }
);

const StyledContainer = styled(Box)(
  ({ theme }) => css`
    background-color: ${theme.palette.secondary.alternate};
    display: flex;
    flex-direction: column;
    padding: ${theme.spacing(12)};
    border-radius: ${theme.borderRadius.large};
  `
);

const StyledInfoList = styled('ul')(
  ({ theme }) => css`
    padding-left: ${theme.spacing(20)};
    margin: 0;
  `
);

const StyledTicketMessagesContainer = styled(Box)(
  ({ theme }) => css`
    > div:not(:first-child) {
      border-top: 1px solid ${theme.palette.natural.border};
      padding-top: ${theme.spacing(20)};
    }
  `
);

const StyledInputContainer = styled(Box)(
  ({ theme }) => css`
    position: relative;
    margin-bottom: ${theme.spacing(16)};

    .input-area textarea:not(:disabled):focus-within,
    .input-area textarea:not(:disabled):active {
      border-color: ${theme.palette.secondary.dark};

      + div svg {
        color: ${theme.palette.secondary.dark};
        opacity: 1;
        cursor: pointer;
      }
    }
  `
);

const StyledPreviewOverlay = styled(Box)<{ hasSuggestions?: boolean }>(
  ({ theme, hasSuggestions }) => css`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: ${theme.spacing(12, 36, 12, 12)};
    padding-bottom: ${hasSuggestions ? theme.spacing(100) : theme.spacing(12)};
    font-size: ${(theme.typography as any)['form-text'].fontSize};
    line-height: 21px;
    color: ${theme.palette.text.secondary};
    pointer-events: none;
    z-index: 1;
    overflow-y: auto;
    white-space: pre-wrap;
    word-break: break-word;
    margin: 1px;
  `
);

const StyledFormTextAreaField = styled(FormTextAreaField, {
  shouldForwardProp: (prop) =>
    ![
      'hasSuggestions',
      'previewText',
      'suggestionsHeight',
      'isLoadingSuggestions',
      'isSending'
    ].includes(prop as string)
})<{
  hasSuggestions?: boolean;
  previewText?: string | null;
  suggestionsHeight?: number;
  isLoadingSuggestions?: boolean;
  isSending?: boolean;
  testHook?: string;
}>(
  ({
    theme,
    hasSuggestions,
    previewText,
    suggestionsHeight,
    isLoadingSuggestions,
    isSending
  }) => css`
    position: relative;

    .input-area textarea {
      min-height: 47px;
      field-sizing: content;
      height: fit-content;
      padding: ${theme.spacing(12, 36, 12, 12)};
      font-size: ${(theme.typography as any)['form-text'].fontSize};
      line-height: 21px;
      resize: none;
      transition: height 0.3s ease-in-out, padding 0.3s ease-in-out;
      color: ${previewText ? 'transparent' : theme.palette.text.primary};
      box-sizing: border-box;
      white-space: pre-wrap;
      word-break: break-word;

      ${(hasSuggestions || isLoadingSuggestions) &&
      !isSending &&
      (suggestionsHeight as number) > 0 &&
      css`
        height: ${(suggestionsHeight as number) + TEXT_HEIGHT_OFFSET}px;
      `}

      ::placeholder {
        font-size: ${(theme.typography as any)['form-text'].fontSize};
      }
    }
  `
);

const StyledInputAction = styled(Box)<{ hasSuggestions?: boolean; suggestionHeight?: number }>(
  ({ theme, hasSuggestions, suggestionHeight }) => css`
    position: absolute;
    right: ${theme.spacing(12)};
    top: 0;
    bottom: 0;
    height: ${SEND_ICON_DIM}px;
    width: ${SEND_ICON_DIM}px;

    ${!hasSuggestions &&
    css`
      margin: auto;
    `}

    ${hasSuggestions &&
    css`
      top: ${((suggestionHeight as number) +
        TEXT_HEIGHT_OFFSET -
        (suggestionHeight as number) -
        SEND_ICON_DIM) /
      2}px;
    `}
    transform: none;
  `
);

const StyledSendIcon = styled(Icon.Send)`
  opacity: 0.4;
`;

const StyledSuggestionsContainer = styled(Box)(
  ({ theme }) => css`
    position: absolute;
    bottom: ${theme.spacing(12)};
    left: ${theme.spacing(12)};
    right: ${theme.spacing(12)};
    z-index: 2;
    background-color: transparent;
    animation: fadeIn 0.3s ease-in-out;

    @keyframes fadeIn {
      from {
        opacity: 0;
        transform: translateY(10px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
  `
);
