// @ts-strict-ignore
import { useCallback, useEffect, useMemo, useState } from 'react';

import { Box } from '@mui/material';

import { css, styled } from '@mui/material/styles';
import { AnalyticEventAction } from 'analytics';
import {
  trackDropdownInputUsageAnalyticsEvent,
  trackInputUsageAnalyticsEvent,
  trackOpenTextInputUsageAnalyticsEvent
} from 'analytics/events/input-usage';
import classNames from 'classnames';

import { NationalNumber } from 'libphonenumber-js';

import { useFormContext } from 'react-hook-form';
import { useUpdateEffect } from 'react-use';

import { useStores } from 'mobx/hooks/useStores';

import { formatDate, getTodayFormattedDate } from 'utils/DateUtils';

import { getNumberAndCountryCodeFromPhone } from 'utils/PhoneUtils';

import { FEATURES } from 'constants/features';

import Patient, { callbackRelationshipOptions, IPatientContact } from 'models/Patient';

import { RHFStyledInput } from 'views/Widgets/StyledInput';
import StyledPhoneInput from 'views/Widgets/StyledPhoneInput';

import { MessagingConsentFormRadioGroup } from 'components/Ticket/TicketForms/MessagingConsentFormRadioGroup';

import { FormLabeledCheckbox } from 'components/UIkit/atoms/Checkbox';
import { FormAutocomplete } from 'components/UIkit/atoms/Dropdown';
import { FormRadioGroup } from 'components/UIkit/atoms/RadioButton';
import { Text } from 'components/UIkit/atoms/Text';

import { NEW_CONTACT_VALUE, PATIENT_IS_CONTACT_VALUE } from './ticket.shared';

const DATE_FORMAT = 'MM/DD/YYYY';

interface IOperatorTicketContactFormProps {
  patient: Patient;
  originalContactHippaAuthDate?: Date;
  originalPatientPhone?: string;
  namePrefix?: string;
}

export const OperatorTicketContactForm = ({
  patient,
  originalContactHippaAuthDate,
  originalPatientPhone,
  namePrefix = ''
}: IOperatorTicketContactFormProps) => {
  const [hipaaDate, setHipaaDate] = useState(getTodayFormattedDate(DATE_FORMAT));
  const { register, setValue, watch, clearErrors, formState, getValues } = useFormContext();
  const { settingsStore } = useStores();
  const isPatientMessagingFeatureEnabled =
    settingsStore.hasFeature(FEATURES.ACTIONS_SEND_PATIENT_SMS) ||
    settingsStore.hasFeature(FEATURES.PATIENT_MESSAGING);
  const hasAlternateTextFeature = settingsStore.hasFeature(FEATURES.ALTERNATE_PHONE_TEXT_CHANGE);
  const { errors } = formState;

  const { contactId, countryCode, callbackName, phone, callbackRelationship, callbackHippaAuth } =
    watch();

  const contactIdAsNumber = parseInt(contactId);

  // set hippa checkbox date
  const setHippaDateBySelection = useCallback((date?: Date) => {
    const newDate = date ? formatDate(date, DATE_FORMAT) : getTodayFormattedDate(DATE_FORMAT);
    setHipaaDate(newDate);
  }, []);

  // set hippa checkbox and hippa date on contact fields change
  useUpdateEffect(
    function setHippaCheckAndDate() {
      let hippaChecked = false;
      const editMode = !!originalContactHippaAuthDate;
      const existContact =
        contactId !== PATIENT_IS_CONTACT_VALUE && contactId !== NEW_CONTACT_VALUE;

      if (editMode && existContact) {
        const contact = patient.contacts.find((contact) => contact.id === contactIdAsNumber);
        if (contact) {
          hippaChecked =
            contact.id === contactIdAsNumber &&
            contact.name === callbackName &&
            contact.phoneNumber === `${countryCode}${phone}` &&
            contact.relationship === callbackRelationship?.value &&
            getValues('callbackHippaAuth');
        }

        setValue('callbackHippaAuth', hippaChecked);
        setHippaDateBySelection(hippaChecked ? originalContactHippaAuthDate : null);
      } else {
        setValue('callbackHippaAuth', hippaChecked);
      }
    },
    [
      contactId,
      patient?.contacts,
      contactIdAsNumber,
      countryCode,
      callbackName,
      phone,
      callbackRelationship,
      originalContactHippaAuthDate,
      setValue,
      setHippaDateBySelection
    ]
  );

  useEffect(
    function onContactChange() {
      clearErrors(['phone', 'callbackName', 'callbackRelationship']);
      let countryCode: string = null;
      let phoneNumber: NationalNumber = null;
      let name: string = null;
      let relationship: string = null;
      let phoneExtension: string = null;
      if (contactId === PATIENT_IS_CONTACT_VALUE) {
        // Set phone number if contact is patient himself
        // In edit mode - originalContactPhoneNumber should be used (can be different from patient.phone)
        const patientPhone = originalPatientPhone || patient?.phone;
        [countryCode, phoneNumber] = getNumberAndCountryCodeFromPhone(patientPhone);
        phoneExtension = patient.phoneExtension;
      } else if (contactId === NEW_CONTACT_VALUE) {
        // Reset fields for new contact
        [countryCode, phoneNumber] = ['+1', null];
      } else {
        // Get details from contact
        const contact = patient.contacts?.find((contact) => contact.id === contactIdAsNumber);
        [countryCode, phoneNumber] = getNumberAndCountryCodeFromPhone(contact?.phoneNumber);
        [name, relationship] = [contact?.name, contact?.relationship];
        phoneExtension = contact?.phoneExtension;
      }

      setValue('phone', phoneNumber || '');
      setValue('countryCode', countryCode);
      setValue('callbackName', name);
      setValue('phoneExtension', phoneExtension);
      setValue(
        'callbackRelationship',
        relationship ? { value: relationship, label: relationship } : null
      );
    },
    [contactId, clearErrors, patient, setValue, contactIdAsNumber, originalPatientPhone]
  );

  const contactOptions = useMemo(() => {
    const patientOption = {
      value: PATIENT_IS_CONTACT_VALUE,
      label: `${patient?.fullName} (Patient)`,
      id: `${namePrefix}${patient?.fullName}`
    };

    const newContactOption = {
      value: NEW_CONTACT_VALUE,
      label: hasAlternateTextFeature ? 'Alternate Number' : 'Someone Else',
      id: `${namePrefix}Someone Else`
    };

    let contactOptions = [newContactOption, patientOption];

    if (patient?.contacts) {
      contactOptions = [
        ...contactOptions,
        ...patient.contacts
          .filter((contact) => contact.id === parseInt(contactId, 10) || !contact.deletedAt)
          .map((contact: IPatientContact) => ({
            value: contact.id.toString(),
            label: `${contact.name} (${contact.relationship})`,
            id: `${namePrefix}${contact.name}`
          }))
      ];
    }

    return contactOptions;
  }, [hasAlternateTextFeature, contactId, namePrefix, patient?.contacts, patient?.fullName]);

  useUpdateEffect(
    function trackCallbackHippaAnalyticsEvent() {
      trackInputUsageAnalyticsEvent({
        action: callbackHippaAuth ? AnalyticEventAction.Add : AnalyticEventAction.Remove,
        value: 'Callback Hippa',
        type: 'checkbox'
      });
    },
    [callbackHippaAuth]
  );

  useUpdateEffect(
    function trackCallbackContactAnalyticsEvent() {
      trackInputUsageAnalyticsEvent({
        action: AnalyticEventAction.Update,
        value: 'Callback Contact',
        type: 'radio'
      });
    },
    [contactId]
  );

  const getPhoneNumberField = (disabled = false) => {
    return (
      <StyledPhoneInputContainer display="flex" gap={8} alignItems="flex-end">
        <StyledPhoneInput
          label="Phone"
          error={Boolean(errors.phone)}
          disabled={disabled}
          onBlur={(_, valueAfterFocus, currentValue) => {
            trackOpenTextInputUsageAnalyticsEvent(
              currentValue,
              valueAfterFocus,
              'Callback Phone Number'
            );
          }}
        />
        <RHFStyledInput
          name="phoneExtension"
          error={Boolean(errors.phoneExtension)}
          register={register}
          placeholder="Extension"
          maxLength={20}
          validate={(v) => !v || /^[a-zA-Z0-9]+$/.test(v)}
        />
      </StyledPhoneInputContainer>
    );
  };

  const getContactUpdateFields = () => {
    return (
      <StyledPhoneInputContainer display="flex" flexDirection="column" gap={20}>
        <StyledRHFStyledInput
          showErrorOutside
          isRequired
          name="callbackName"
          label="Name"
          register={register}
          error={Boolean(errors.callbackName)}
          getValues={getValues}
          onBlur={(_, currentValue, valueAfterFocus) =>
            trackOpenTextInputUsageAnalyticsEvent(currentValue, valueAfterFocus, 'Callback Name')
          }
        />
        <FormAutocomplete
          label="Relationship"
          isRequired
          name="callbackRelationship"
          options={callbackRelationshipOptions}
          isClearable={false}
          onChange={(_, actionMeta, eventKey) =>
            trackDropdownInputUsageAnalyticsEvent(
              actionMeta,
              'Relationship to Patient',
              eventKey === 'Enter'
            )
          }
        />
        {getPhoneNumberField()}
        <FormLabeledCheckbox
          name="callbackHippaAuth"
          id={`${namePrefix}callbackHippaAuth`}
          label={`I’ve verified that this person is HIPAA-authorized as of ${hipaaDate}`}
        />
      </StyledPhoneInputContainer>
    );
  };

  const renderAdditionalFields = () => {
    if (contactId === PATIENT_IS_CONTACT_VALUE) {
      return getPhoneNumberField(true);
    }
    return getContactUpdateFields();
  };

  const contactsContainerClassNames = classNames('field contact-selection', {
    small: namePrefix === 'edit'
  });

  return (
    <Box>
      <Text variant="h4" component="h4">
        Who should we get back to?
      </Text>
      <Box pt={20}>
        <input {...register('countryCode')} type="hidden" />
        <div className={contactsContainerClassNames}>
          <FormRadioGroup name="contactId" options={contactOptions} />
        </div>
      </Box>
      <StyledSeparator />
      {renderAdditionalFields()}

      {isPatientMessagingFeatureEnabled && namePrefix !== 'edit' && (
        <>
          <StyledSeparator />
          <MessagingConsentFormRadioGroup />
        </>
      )}
    </Box>
  );
};

const StyledPhoneInputContainer = styled(Box)`
  .phone-input {
    flex: 1;
    .phone-input-number-container {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
      input {
        width: 110px;
      }
    }
  }

  .styled-input {
    width: 166px;
    .form-control {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
`;

const StyledSeparator = styled('hr')(
  ({ theme }) => css`
    border-top: 1px solid ${theme.palette.natural.border};
    margin: ${theme.spacing(20)} 0;
  `
);

const StyledRHFStyledInput = styled(RHFStyledInput)(
  ({ theme }) => css`
    &.styled-input {
      width: 100%;
      flex: 1;
      .form-control {
        border-radius: 0 ${theme.borderRadius.large} ${theme.borderRadius.large}
          ${theme.borderRadius.large};
      }
    }
  `
);
