import { FC } from 'react';

import { useFormContext } from 'react-hook-form';

import { FormRadioGroup } from 'components/UIkit/atoms/RadioButton';
import { Text } from 'components/UIkit/atoms/Text';

export const MessagingConsentFormRadioGroup: FC = () => {
  const { formState } = useFormContext();

  const getRadioButtonsGroupLabelColor = () => {
    if (formState.errors.messagingConsent) {
      return 'error';
    }

    return 'primary';
  };

  const color = getRadioButtonsGroupLabelColor();

  const consentOptions = [
    {
      label: (
        <Text variant="body2" color={color}>
          <Text variant="body1" color={color}>
            Yes:
          </Text>{' '}
          Patient consents to updates via text message, and understands this may contain personal
          health information.
        </Text>
      ),
      value: true,
      id: 'messagingConsentYes'
    },
    {
      label: (
        <Text variant="body2" color={color}>
          <Text variant="body1" color={color}>
            No:
          </Text>{' '}
          Opt out of text messages.
        </Text>
      ),
      value: false,
      id: 'messagingConsentNo'
    }
  ];

  return (
    <>
      <Text variant="h4" component="h4" color={color} pb={20}>
        Can we text you?
      </Text>
      <FormRadioGroup name="messagingConsent" options={consentOptions} required />
    </>
  );
};
