// @ts-strict-ignore
import { FunctionComponent, ReactElement, ReactNode } from 'react';

import { styled } from '@mui/material/styles';

import classNames from 'classnames';
import { observer } from 'mobx-react';

import { useLocation } from 'react-router-dom';
import { ticketSelectors } from 'tests/models/components/ticket/ticket.selectors';

import { useStores } from 'mobx/hooks/useStores';

import { Testable } from 'utils/TypeUtils';

import Patient from 'models/Patient';
import PatientTag from 'models/PatientTag';

import { HIGHLIGHT_TICKET_QUERY_PARAM } from 'views/Patient/PatientMain/PatientMainView.constants';

import HorizontalLoader from 'views/Widgets/HorizontalLoader';

import PatientDynamicTags from 'components/Patient/Table/PatientDynamicTags';
import Card from 'components/Surfaces/Card';
import useTicketOverviewContext from 'components/Ticket/TicketsContainers/useTicketOverviewContext';

import { Checkbox } from 'components/UIkit/atoms/Checkbox';
import { InternalLink } from 'components/UIkit/atoms/Link';

import './TicketRenderer.scss';

const DECEASED_TAG_ID = -1;

export enum TicketAlertType {
  HIGH = 'high',
  MEDIUM = 'medium'
}
export interface TicketRendererClasses {
  container?: string;
  patientDetails?: string;
  body?: string;
  actions?: string;
  alert?: TicketAlertType;
}

export interface TicketRendererProps extends Testable {
  children?: ReactNode;
  ticketId?: number; // required for bulk actions
  hideBulk?: boolean;
  actions?: ReactNode;
  summary?: ReactNode;
  patient?: Patient;
  createdByText?: ReactNode;
  classes?: TicketRendererClasses;
  icon?: ReactElement;
  withPatientLink?: boolean;
  isLoading?: boolean;
  dashedBorder?: boolean;
  ticketRefsCallback?: (node: HTMLElement, ticketId: number) => void;
}

const PatientDetailsRenderer = ({
  patient,
  createdByText,
  withPatientLink
}: {
  patient: Patient;
  createdByText: ReactNode;
  withPatientLink: boolean;
}) => {
  const { constantsStore } = useStores();

  const renderName = () =>
    !withPatientLink ? (
      <div className="bold patient-name black details-row">{patient.fullName}</div>
    ) : (
      <Wrapper>
        <InternalLink
          to={`/patient/${patient.id}`}
          size="large"
          isEllipsis
          variant="secondary"
          testHook={ticketSelectors.patientLink(patient.id)}
        >
          {patient.fullName}
        </InternalLink>
      </Wrapper>
    );

  if (!patient) return null;

  const patientTags = constantsStore.getTagsByIds(patient.tags);

  if (patient.isDeceased) {
    patientTags.unshift(new PatientTag({ name: 'Deceased', id: DECEASED_TAG_ID }));
  }

  return (
    <>
      {renderName()}
      {patient.tags && <PatientDynamicTags tags={patientTags} />}
      <div className="date-text details-row">{createdByText}</div>
    </>
  );
};

const Wrapper = styled('span')`
  width: 100%;

  span {
    // added to compensate for text cutting from the left (EH-4429)
    padding-left: 1px;
  }
`;

const TicketRenderer: FunctionComponent<TicketRendererProps> = ({
  ticketId,
  hideBulk,
  children,
  patient,
  createdByText,
  icon,
  actions,
  summary,
  classes = {},
  withPatientLink,
  isLoading,
  dashedBorder,
  ticketRefsCallback,
  testHook
}) => {
  const { allowBulkActions } = useTicketOverviewContext() || {
    allowBulkActions: false
  };
  const { ticketsStore } = useStores();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const highlightId = Number(params.get(HIGHLIGHT_TICKET_QUERY_PARAM));

  const toggleCheck = () => {
    ticketsStore.toggleBulkTicket(ticketId);
  };

  const ticketSelected = ticketId && ticketsStore.ticketsBulkActionSet.has(ticketId);
  const containerClasses = classNames('ticket-renderer', classes.container, {
    loading: isLoading
  });

  const detailsClasses = classNames('ticket-renderer-patient-details', classes.patientDetails);
  const summaryClasses = classNames('ticket-renderer-summary', classes.body);
  const actionsClasses = classNames('ticket-renderer-actions', classes.actions);

  const showBulkActions = allowBulkActions && ticketId && !hideBulk;

  return (
    <Card dashedBorder={dashedBorder} highlight={highlightId === ticketId} testHook={testHook}>
      <div
        className={containerClasses}
        ref={(ref) => {
          if (ticketRefsCallback) {
            ticketRefsCallback(ref, ticketId);
          }
          return ref;
        }}
      >
        {isLoading && <HorizontalLoader />}
        {showBulkActions && (
          <div className="bulk-checkbox-wrapper">
            <Checkbox
              checked={ticketSelected}
              onChange={toggleCheck}
              variant="secondary"
              id={`ticket-bulk-checkbox-${ticketId}`}
            />
          </div>
        )}
        <div className="ticket-renderer-main">
          <div className="icon-wrapper">{icon}</div>
          <div className={detailsClasses}>
            <PatientDetailsRenderer
              patient={patient}
              createdByText={createdByText}
              withPatientLink={withPatientLink}
            />
          </div>
          <div className={summaryClasses}>{summary}</div>
          <div className={actionsClasses}>{actions}</div>
        </div>
        {children}
      </div>
    </Card>
  );
};

export default observer(TicketRenderer);
