import { FC } from 'react';

import { observer } from 'mobx-react';
import { ticketSelectors } from 'tests/models/components/ticket/ticket.selectors';

import { TicketType } from 'tests/models/components/ticket/ticket.types';

import { useTicketNetworkLoading } from 'mobx/hooks/useNetworkLoading';

import { useStores } from 'mobx/hooks/useStores';

import { useTicketCalls } from 'mobx/hooks/useTicketCalls';

import Ticket from 'models/Ticket';

import CardStack from 'views/Widgets/CardStack';

import { TicketPatientCallsList } from 'views/Widgets/PatientCallsList';

import { TicketSectionId } from 'views/WorkQueue/WorkQueue.types';

import { usePatientModel } from 'components/Patient/usePatientModel';
import { CallbackTicketSummary } from 'components/Ticket/TicketRow/CallbackTicketSummary';
import { TicketCommunicationSection } from 'components/Ticket/TicketRow/TicketCommunicationSection/TicketComments/TicketCommunicationSection';
import TicketCreatedBy from 'components/Ticket/TicketRow/TicketCreatedBy';

import TicketRenderer from 'components/Ticket/TicketRow/TicketRenderer';

import { TicketSummaryButton } from 'components/Ticket/TicketRow/TicketSummaryButton/TicketSummaryButton';

import { useTicketToggle } from 'components/Ticket/TicketRow/useTicketToggle';
import useTicketOverviewContext from 'components/Ticket/TicketsContainers/useTicketOverviewContext';

interface Props {
  ticket: Ticket;
  withPatientLink: boolean;
  ticketRefsCallback?: (node: HTMLElement, ticketId: number) => void;
  className?: string;
  ticketIndex: number;
  ticketSectionCurrentPage?: number;
  ticketSectionId: TicketSectionId;
}

const CallbackRequestTicketRow: FC<Props> = ({
  ticketRefsCallback,
  ticket,
  withPatientLink,
  className,
  ticketIndex,
  ticketSectionCurrentPage = 0,
  ticketSectionId
}) => {
  const patient = usePatientModel(ticket.patientId);
  const { ticketActions: Actions, tab, hideActionButtons } = useTicketOverviewContext();
  const { isExpanded, toggle } = useTicketToggle(
    ticket.id,
    false,
    ticketSectionCurrentPage,
    false,
    ticket.messagesCount > 0,
    tab
  );
  const { ticketsStore } = useStores();
  const { calls, isLoadingCalls } = useTicketCalls(ticket, isExpanded);
  const isActionInProgress = useTicketNetworkLoading(ticket.id);

  const isTicketSelected = ticketsStore.ticketsBulkActionSet.has(ticket.id);

  const getTicketSummary = () => (
    <div>
      <CallbackTicketSummary ticket={ticket} patient={patient} />

      <TicketSummaryButton
        onClick={toggle}
        isExpanded={isExpanded}
        ticketId={ticket.id}
        items={{
          draft: ticket.draftCallCount,
          comment: ticket.commentCount,
          call: ticket.callCount,
          callAttempt: ticket.callAttemptsCount
        }}
      />
    </div>
  );

  return (
    <CardStack
      isSelected={isTicketSelected}
      isOpen={isExpanded}
      classes={{
        root: className,
        closed: 'report-row-closed'
      }}
      numberOfStackedItems={ticket.callsAndDraftsCount}
    >
      <TicketRenderer
        testHook={ticketSelectors.container(ticket.id, TicketType.CallbackRequest, ticketSectionId)}
        ticketRefsCallback={ticketRefsCallback}
        isLoading={isActionInProgress}
        ticketId={ticket.id}
        withPatientLink={withPatientLink}
        classes={{
          container: 'no-border'
        }}
        patient={patient}
        createdByText={<TicketCreatedBy ticket={ticket} />}
        summary={getTicketSummary()}
        actions={
          <Actions
            ticket={ticket}
            ticketIndex={ticketIndex}
            ticketSectionCurrentPage={ticketSectionCurrentPage}
          />
        }
      >
        {isExpanded && (
          <TicketCommunicationSection
            ticket={ticket}
            ticketIndex={ticketIndex}
            ticketSectionCurrentPage={ticketSectionCurrentPage}
          />
        )}
      </TicketRenderer>
      {TicketPatientCallsList({
        calls,
        patient,
        isLoadingCalls,
        ticket,
        hideActionButtons
      })}
    </CardStack>
  );
};

export default observer(CallbackRequestTicketRow);
