import { FC, FocusEvent, useEffect, useState, Fragment } from 'react';

import { Box } from '@mui/material';
import { css, styled } from '@mui/material/styles';
import { AnalyticEventAction } from 'analytics';
import { trackActionButtonAnalyticsEvent } from 'analytics/events/action-button';
import { Dialogs } from 'analytics/events/dialog';
import { trackEditContactModalAnalyticsEvent } from 'analytics/events/edit-contact-modal';
import {
  trackCheckboxInputUsageAnalyticsEvent,
  trackOpenTextInputUsageAnalyticsEvent
} from 'analytics/events/input-usage';
import { trackSectionUsageAnalyticsEvent } from 'analytics/events/section-usage';

import { isNil } from 'lodash/fp';
import { observer } from 'mobx-react';

import { FormProvider, useController, useFormContext } from 'react-hook-form';

import { patientPageTestSelectors } from 'tests/models/pages/patient-page/patient-page.selectors';

import { useStores } from 'mobx/hooks/useStores';
import { HomeCareInstructionActionType } from 'mobx/stores';

import { PatientCommunicationFetcher } from 'fetchers/PatientCommunicationFetcher';

import { fromNow } from 'utils/DateUtils';
import { getFormattedPhoneNumber } from 'utils/PhoneUtils';

import { convertHtmlToMarkdown, pluralize } from 'utils/StringUtils';

import { showToast } from 'utils/UserMessageUtils';

import { CommunicationType } from 'models/Call';
import { ContactOption, CommunicationTypeToLabelMap } from 'models/ContactOptions';

import Patient, { IPatientContact } from 'models/Patient';

import EditPatientInfoModal from 'views/Modals/EditPatientInfoModal';

import MultipleLandlineSmsPopup from 'views/Modals/MultipleLandlineSmsPopup';
import MultipleSmsBlockedPopup from 'views/Modals/MultipleSmsBlockedPopup';
import { PatientContactModalForm } from 'views/Modals/PatientContactModalForm';
import { HOMECARE_INSTRUCTIONS_TEXTAREA_MAX_LENGTH } from 'views/Widgets/CallLogging/CallLogging.constants';

import ExpandableRow from 'views/Widgets/ExpandableRow';
import StyledInput from 'views/Widgets/StyledInput';

import Icon from 'components/Icons/Icon';
import { OutlinedIconButton } from 'components/UIkit/atoms/Button';
import { FormLabeledCheckbox } from 'components/UIkit/atoms/Checkbox';
import { MessageDialog } from 'components/UIkit/atoms/Dialog';
import { FormContactsMultiAutocomplete } from 'components/UIkit/atoms/Dropdown/Select/FeatureSpecific/FormContactsMultiAutocomplete/FormContactsMultiAutocomplete';
import { Text } from 'components/UIkit/atoms/Text';

interface Props {
  patient: Patient;
}

export interface HomeCareInstructionsFieldValues {
  recipients: ContactOption[];
  text: string;
  messagingConsent: boolean;
}

interface GroupedRecipient {
  name: string;
  types: CommunicationType[];
  contactId?: number;
}

interface GroupedInstruction {
  text: string;
  createdAt: string;
  recipients: GroupedRecipient[];
  ids: number[];
}

export const HomeCareInstructionsSection: FC<Props> = observer(({ patient }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { pathwaysStore, callLoggingStore, patientPageStore } = useStores();
  const [isExpanded, setIsExpanded] = useState(
    Boolean(pathwaysStore.homeCareInstructionsAnswers.length > 0)
  );
  const [isPatientContactModalOpen, setIsPatientContactModalOpen] = useState(false);
  const [isPatientModalOpen, setIsPatientModalOpen] = useState(false);
  const [contactToEdit, setContactToEdit] = useState<IPatientContact | null>(null);
  const [contactToRemove, setContactToRemove] = useState<IPatientContact | null>(null);
  const [blockedSmsNames, setBlockedSmsNames] = useState<string[]>([]);
  const [landlineSmsNames, setLandlineSmsNames] = useState<string[]>([]);
  const [isRemovingContact, setIsRemovingContact] = useState(false);

  const methods = useFormContext<HomeCareInstructionsFieldValues>();
  const { getValues, setValue, resetField, control, handleSubmit, watch } = methods;
  const { field } = useController({
    control,
    name: 'text'
  });
  const [currentText, currentMessagingConsent, currentRecipients] = watch([
    'text',
    'messagingConsent',
    'recipients'
  ]);

  useEffect(
    function autoExpandSectionOnAddHomeCareInstructionsAnswer() {
      if (pathwaysStore.homeCareInstructionsAnswers.length > 0) {
        setIsExpanded(true);
      }
    },
    [pathwaysStore.homeCareInstructionsAnswers]
  );

  useEffect(
    function refreshGroupedInstructionsOnHomeCareInstructionsChange() {
      if (callLoggingStore.currentCall.homeCareInstructions?.length) {
        setIsExpanded(true);
      }
    },
    [callLoggingStore.currentCall.homeCareInstructions]
  );

  const removeMarkdownsFromText = (text: string, titlesToRemove: string[] = []) => {
    return titlesToRemove
      .map((title) => convertHtmlToMarkdown(title))
      .reduce((updatedText, markdown) => updatedText.replace(markdown, ''), text);
  };

  useEffect(
    function handleHomeCareInstructionsPathwayAnswerChanged() {
      const homeCareInstructionsDraftText = getValues('text');

      switch (pathwaysStore.lastHomeCareInstructionsOptionClicked.action) {
        case HomeCareInstructionActionType.Add: {
          const markdown = convertHtmlToMarkdown(
            pathwaysStore.lastHomeCareInstructionsOptionClicked.title
          );

          let newHomeCareInstructionsDraftText = homeCareInstructionsDraftText.trim();

          if (newHomeCareInstructionsDraftText) {
            newHomeCareInstructionsDraftText += '\n\n';
          }

          newHomeCareInstructionsDraftText += markdown;

          setValue('text', newHomeCareInstructionsDraftText.trim());

          break;
        }
        case HomeCareInstructionActionType.Remove: {
          const { titlesToRemove } = pathwaysStore.lastHomeCareInstructionsOptionClicked;

          const newHomeCareInstructionsDraftText = removeMarkdownsFromText(
            homeCareInstructionsDraftText.trim(),
            titlesToRemove
          );

          setValue('text', newHomeCareInstructionsDraftText.trim());
          break;
        }
        case HomeCareInstructionActionType.Replace: {
          const markdown = convertHtmlToMarkdown(
            pathwaysStore.lastHomeCareInstructionsOptionClicked.title
          );

          let newHomeCareInstructionsDraftText = homeCareInstructionsDraftText.trim();

          newHomeCareInstructionsDraftText += markdown;

          const { titlesToRemove } = pathwaysStore.lastHomeCareInstructionsOptionClicked;

          newHomeCareInstructionsDraftText = removeMarkdownsFromText(
            newHomeCareInstructionsDraftText.trim(),
            titlesToRemove
          );

          setValue('text', newHomeCareInstructionsDraftText.trim());
          break;
        }
      }
    },
    [pathwaysStore.lastHomeCareInstructionsOptionClicked, setValue, getValues, pathwaysStore]
  );

  const extractRecipientsFromFieldValues = (fieldValues: HomeCareInstructionsFieldValues) => {
    if (!fieldValues.recipients?.length) {
      return [];
    }

    const recipientsByContactId: Record<
      string,
      {
        communicationTypes: CommunicationType[];
        contactId?: number;
      }
    > = {};

    fieldValues.recipients.forEach((recipient) => {
      const contactIdKey = recipient.contactId ? String(recipient.contactId) : 'patient';

      if (!recipientsByContactId[contactIdKey]) {
        recipientsByContactId[contactIdKey] = {
          ...(recipient.contactId !== undefined && { contactId: recipient.contactId }),
          communicationTypes: []
        };
      }

      if (
        !recipientsByContactId[contactIdKey].communicationTypes.includes(
          recipient.communicationType
        )
      ) {
        recipientsByContactId[contactIdKey].communicationTypes.push(recipient.communicationType);
      }
    });

    return Object.values(recipientsByContactId);
  };

  const onSubmit = async (fieldValues: HomeCareInstructionsFieldValues) => {
    trackActionButtonAnalyticsEvent({
      action: AnalyticEventAction.Send,
      virtual_page: 'call logger',
      patient_id: patient.id
    });
    setIsLoading(true);

    if (fieldValues.messagingConsent !== patient.hasMessagingConsent) {
      await PatientCommunicationFetcher.updateMessagingConsent(
        patient.id,
        fieldValues.messagingConsent
      );
    }

    showToast({
      message: 'Sending Homecare Instructions'
    });

    const recipients = extractRecipientsFromFieldValues(fieldValues);

    const { ids, blockedSmsContacts, patientBlockedSms, patientLandline, landlinePhoneContacts } =
      await PatientCommunicationFetcher.sendHomeCareInstructions(patient.id, {
        text: fieldValues.text,
        recipients,
        ...(callLoggingStore.currentCall?.id && { callId: callLoggingStore.currentCall.id })
      });

    const newInstructionsCreatedAt = new Date().toISOString();

    const newHomeCareInstructions = [...(callLoggingStore.currentCall.homeCareInstructions || [])];

    fieldValues.recipients.forEach((recipient) => {
      const isSmsRecipient = recipient.communicationType === CommunicationType.Sms;
      const isEmailRecipient = recipient.communicationType === CommunicationType.Email;

      if (recipient.contactId) {
        const contactBlockedSms = blockedSmsContacts.includes(recipient.contactId!);
        const contactHasLandline = landlinePhoneContacts.includes(recipient.contactId!);
        if ((contactBlockedSms || contactHasLandline) && isSmsRecipient) {
          return;
        }
      }

      if (!recipient.contactId && (patientBlockedSms || patientLandline) && isSmsRecipient) {
        return;
      }

      const contactId = recipient.contactId || null;

      newHomeCareInstructions.push({
        createdAt: newInstructionsCreatedAt,
        ids,
        patientId: patient.id,
        text: fieldValues.text,
        contactId,
        phoneNumber: isSmsRecipient ? recipient.actualValue : null,
        email: isEmailRecipient ? recipient.actualValue : null,
        ...(callLoggingStore.currentCall?.id && { callId: callLoggingStore.currentCall.id })
      });
    });

    callLoggingStore.updateCurrentCall({
      homeCareInstructions: newHomeCareInstructions
    });

    if (blockedSmsContacts.length || patientBlockedSms) {
      const names = patient.contacts
        .filter((contact) => blockedSmsContacts.includes(contact.id!))
        .map((contact) => contact.name!);
      if (patientBlockedSms) {
        names.push(patient.fullName);
      }
      setBlockedSmsNames(names);
    }
    if (landlinePhoneContacts.length || patientLandline) {
      const names = patient.contacts
        .filter((contact) => landlinePhoneContacts.includes(contact.id!))
        .map((contact) => contact.name!);
      if (patientLandline) {
        names.push(patient.fullName);
      }
      setLandlineSmsNames(names);
    }
    resetField('text', { defaultValue: '' });

    setIsLoading(false);
  };

  const handleSectionToggle = () => {
    trackSectionUsageAnalyticsEvent({
      action: isExpanded ? AnalyticEventAction.Collapse : AnalyticEventAction.Expand,
      value: 'Homecare Instructions',
      virtual_page: 'call logger'
    });

    setIsExpanded(!isExpanded);
  };

  const homeCareInstructionsLength = pathwaysStore.homeCareInstructionsAnswers.length;

  const getGroupedHomeCareInstructions = () => {
    if (!callLoggingStore.currentCall.homeCareInstructions?.length) {
      return [];
    }

    const groupedByTextAndRoundedTime: Record<string, GroupedInstruction> = {};

    for (const instruction of callLoggingStore.currentCall.homeCareInstructions) {
      const date = new Date(instruction.createdAt);
      date.setSeconds(0, 0);
      const roundedTimestamp = date.toISOString();

      const key = `${instruction.text}-${roundedTimestamp}`;

      if (!groupedByTextAndRoundedTime[key]) {
        groupedByTextAndRoundedTime[key] = {
          text: instruction.text,
          createdAt: instruction.createdAt,
          recipients: [],
          ids: [...instruction.ids]
        };
      } else {
        groupedByTextAndRoundedTime[key].ids.push(...instruction.ids);
      }

      let recipientName: string;
      if (instruction.contactId) {
        const contact = patient.contacts?.find((c) => c.id === instruction.contactId);
        recipientName = contact?.name?.split(' ')[0] || 'Unknown Contact';
      } else {
        recipientName = patient.firstName;
      }

      let recipient = groupedByTextAndRoundedTime[key].recipients.find(
        (r) => r.name === recipientName
      );

      if (!recipient) {
        recipient = {
          name: recipientName,
          types: [],
          contactId: instruction.contactId
        };
        groupedByTextAndRoundedTime[key].recipients.push(recipient);
      }

      const type = instruction.phoneNumber ? CommunicationType.Sms : CommunicationType.Email;
      if (!recipient.types.includes(type)) {
        recipient.types.push(type);
      }
    }

    for (const group of Object.values(groupedByTextAndRoundedTime)) {
      group.recipients.sort((a, b) => (!a.contactId ? -1 : !b.contactId ? 1 : 0));
    }

    return Object.values(groupedByTextAndRoundedTime);
  };

  const groupedHomeCareInstructions = getGroupedHomeCareInstructions();

  const openPatientModal = () => {
    setIsPatientModalOpen(true);
  };

  const openPatientContactModal = (contactId?: number) => {
    setIsPatientContactModalOpen(true);
    trackEditContactModalAnalyticsEvent({
      value: contactId ? 'edit' : 'new',
      action: AnalyticEventAction.Open,
      patientId: patient.id
    });

    if (contactId) {
      const contact = patient.contacts.find((c) => c.id === contactId);
      if (contact) {
        setContactToEdit(contact);
      }
    } else {
      setContactToEdit(null);
    }
  };

  const handleContactSuccess = (updatedContact: IPatientContact) => {
    let newRecipients = currentRecipients;
    if (contactToEdit?.phoneNumber !== updatedContact.phoneNumber) {
      const addedOrEditedPhone = currentRecipients?.find(
        (recipient) =>
          recipient.contactId === updatedContact.id &&
          recipient.communicationType === CommunicationType.Sms
      );
      if (!addedOrEditedPhone) {
        newRecipients.push({
          label: getFormattedPhoneNumber(updatedContact.phoneNumber as string) as string,
          value: `${updatedContact.id}_${CommunicationType.Sms}`,
          actualValue: updatedContact.phoneNumber,
          communicationType: CommunicationType.Sms,
          contactId: updatedContact.id
        });
      }
    }
    if (contactToEdit?.email !== updatedContact.email && updatedContact.email) {
      const addedOrEditedEmail = currentRecipients?.find(
        (recipient) =>
          recipient.contactId === updatedContact.id &&
          recipient.communicationType === CommunicationType.Email
      );
      if (!addedOrEditedEmail) {
        newRecipients.push({
          label: updatedContact.email as string,
          value: `${updatedContact.id}_${CommunicationType.Email}`,
          actualValue: updatedContact.email,
          communicationType: CommunicationType.Email,
          contactId: updatedContact.id
        });
      }
    }
    if (contactToEdit?.id && currentRecipients?.length) {
      newRecipients = currentRecipients
        ?.filter((recipient: ContactOption) => {
          if (recipient.contactId !== updatedContact.id) return true;

          if (recipient.communicationType === CommunicationType.Email && !updatedContact.email) {
            return false;
          }

          return true;
        })
        ?.map((recipient: ContactOption) => {
          if (recipient.contactId !== updatedContact.id) return recipient;

          return {
            ...recipient,
            actualValue:
              recipient.communicationType === CommunicationType.Sms
                ? updatedContact.phoneNumber
                : updatedContact.email,
            label:
              recipient.communicationType === CommunicationType.Sms
                ? (getFormattedPhoneNumber(updatedContact.phoneNumber as string) as string)
                : (updatedContact.email as string)
          };
        });
    }

    setValue('recipients', newRecipients);
    setContactToEdit(null);
    setIsPatientContactModalOpen(false);
  };

  const removeContact = async () => {
    if (contactToRemove && !isRemovingContact) {
      try {
        setIsRemovingContact(true);
        await patientPageStore.deleteContact(patient.id, contactToRemove);
        if (currentRecipients?.length) {
          const newRecipients = currentRecipients.filter(
            (recipient) => recipient.contactId !== contactToRemove.id
          );
          setValue('recipients', newRecipients);
        }

        setIsPatientContactModalOpen(false);
        setContactToRemove(null);
        setContactToEdit(null);
      } finally {
        setIsRemovingContact(false);
      }
    }
  };

  const onPatientModalSuccess = () => {
    if (currentRecipients?.length) {
      const newRecipients = currentRecipients
        .filter((recipient: ContactOption) => {
          const isPatient = isNil(recipient.contactId);
          const isEmail = recipient.communicationType === CommunicationType.Email;

          if (isPatient && isEmail && !patient.email) {
            return false;
          }

          return true;
        })
        .map((recipient: ContactOption) => {
          if (!isNil(recipient.contactId)) return recipient;

          return {
            ...recipient,
            actualValue:
              recipient.communicationType === CommunicationType.Sms ? patient.phone : patient.email,
            label:
              recipient.communicationType === CommunicationType.Sms
                ? (getFormattedPhoneNumber(patient.phone as string) as string)
                : (patient.email as string)
          };
        });

      setValue('recipients', newRecipients);
    }

    setIsPatientModalOpen(false);
  };

  const onPatientModalCancel = () => {
    setIsPatientModalOpen(false);
  };

  return (
    <>
      <MessageDialog
        id={Dialogs.RemoveCallbackContact}
        isOpen={contactToRemove !== null}
        handleClose={() => setContactToRemove(null)}
        title="Remove this callback contact?"
        primaryActionProps={{
          text: isRemovingContact ? 'Removing...' : 'Remove',
          onClick: removeContact,
          disabled: isRemovingContact
        }}
        secondaryActionProps={{ text: 'Cancel', onClick: () => setContactToRemove(null) }}
      >
        {contactToRemove ? `"${contactToRemove.name}"` : ''}
      </MessageDialog>
      <MultipleSmsBlockedPopup
        isOpen={Boolean(blockedSmsNames.length)}
        onCancelClicked={() => setBlockedSmsNames([])}
        names={blockedSmsNames}
      />
      <MultipleLandlineSmsPopup
        isOpen={Boolean(landlineSmsNames.length && !blockedSmsNames.length)}
        onCancelClicked={() => setLandlineSmsNames([])}
        names={landlineSmsNames}
      />
      <EditPatientInfoModal
        patient={patient}
        key={patient.id}
        isOpen={isPatientModalOpen}
        onEditSuccessful={onPatientModalSuccess}
        onCancelClicked={onPatientModalCancel}
      />
      <PatientContactModalForm
        patient={patient}
        contact={contactToEdit}
        isOpen={isPatientContactModalOpen}
        onDelete={() => setContactToRemove(contactToEdit)}
        onCancel={() => {
          trackEditContactModalAnalyticsEvent({
            value: contactToEdit?.id ? 'edit' : 'new',
            action: AnalyticEventAction.Cancel,
            patientId: patient.id
          });
          setContactToEdit(null);
          setIsPatientContactModalOpen(false);
        }}
        onSuccess={(patientContact) => {
          trackEditContactModalAnalyticsEvent({
            value: contactToEdit?.id ? 'edit' : 'new',
            action: AnalyticEventAction.Save,
            patientId: patient.id
          });
          handleContactSuccess(patientContact);
        }}
      />
      <ExpandableRow
        testHooks={{
          container: patientPageTestSelectors.callLogger.homeCareInstructionsSection.container,
          header: patientPageTestSelectors.callLogger.homeCareInstructionsSection.header
        }}
        isExpanded={isExpanded}
        onToggleExpand={handleSectionToggle}
        title={
          <Text>
            <Text variant="h4">Homecare instructions</Text>

            {homeCareInstructionsLength > 0 && (
              <Text variant="h4" color="secondary">{` — ${homeCareInstructionsLength} ${pluralize(
                'Pathway Response',
                homeCareInstructionsLength
              )}
              Added`}</Text>
            )}
          </Text>
        }
      >
        <Box>
          {groupedHomeCareInstructions.map((item) => {
            const relativeTime = fromNow(new Date(item.createdAt));

            return (
              <StyledSentHomeCareInstructionsContainer key={`${item.text}-${item.ids.join('-')}`}>
                <Text component="div" color="secondary" mb={2}>
                  Sent{' '}
                  <Text color="secondary" textTransform="capitalize">
                    {relativeTime}
                  </Text>{' '}
                  to{' '}
                  {item.recipients.map((recipient, index) => (
                    <Fragment key={`${recipient.name}-${recipient.types.join('-')}`}>
                      {index > 0 && ', '}
                      <Text color="secondary" component="span">
                        {recipient.name} (
                        {recipient.types
                          .map((type) => CommunicationTypeToLabelMap[type])
                          .join(', ')}
                        )
                      </Text>
                    </Fragment>
                  ))}
                </Text>

                <Text variant="form-text" color="secondary">
                  {item.text}
                </Text>

                <StyledSeparator />
              </StyledSentHomeCareInstructionsContainer>
            );
          })}

          <FormProvider {...methods}>
            <StyledFormTextAreaField
              placeholder="Start typing homecare instructions here. Relevant responses will be added automatically."
              onChange={field.onChange}
              onBlur={(
                _event: FocusEvent<HTMLInputElement>,
                currentValue: string,
                valueAfterFocus: string
              ) =>
                trackOpenTextInputUsageAnalyticsEvent(
                  currentValue,
                  valueAfterFocus,
                  'Homecare Instructions Text Message',
                  { patient_id: patient.id }
                )
              }
              name={field.name}
              value={field.value}
              maxLength={HOMECARE_INSTRUCTIONS_TEXTAREA_MAX_LENGTH}
              type="textarea"
              testHook={patientPageTestSelectors.callLogger.homeCareInstructionsSection.textarea}
            />

            <Box display="flex" flexDirection="column">
              {!patient.messagingConsent && (
                <Box mb={20}>
                  <FormLabeledCheckbox
                    label="Patient consents to receiving updates about tickets via text message / email and understands these messages may contain personal health information."
                    name="messagingConsent"
                    required
                    testHook={
                      patientPageTestSelectors.callLogger.homeCareInstructionsSection
                        .messagingConsentCheckbox
                    }
                    onChange={() =>
                      trackCheckboxInputUsageAnalyticsEvent(
                        currentMessagingConsent,
                        'Homecare Instructions Consent'
                      )
                    }
                  />
                </Box>
              )}
              <Box display="flex" alignItems="center" gap={8}>
                <Box flex={5}>
                  <FormContactsMultiAutocomplete
                    patient={patient}
                    name="recipients"
                    openPatientModal={openPatientModal}
                    openPatientContactModal={openPatientContactModal}
                    isDisabled={!currentMessagingConsent}
                  />
                </Box>
                <Box flex={1}>
                  <OutlinedIconButton
                    ml="auto"
                    icon={<Icon.Send />}
                    onClick={handleSubmit(onSubmit)}
                    hasBoxShadow={false}
                    disabled={
                      !Boolean(currentText?.trim()) || !currentRecipients?.length || isLoading
                    }
                    testHook={
                      patientPageTestSelectors.callLogger.homeCareInstructionsSection.sendButton
                    }
                  >
                    Send
                  </OutlinedIconButton>
                </Box>
              </Box>
            </Box>
          </FormProvider>
        </Box>
      </ExpandableRow>
    </>
  );
});

const StyledSentHomeCareInstructionsContainer = styled(Box)(
  ({ theme }) => css`
    white-space: break-spaces;

    &:not(:last-of-type) {
      margin-bottom: ${theme.spacing(24)};
    }
  `
);

const StyledSeparator = styled('hr')(
  ({ theme }) => css`
    margin-top: ${theme.spacing(24)};
    margin-bottom: 0;
  `
);

const StyledFormTextAreaField = styled(StyledInput)`
  &.styled-input {
    margin-bottom: 34px;

    .input-area textarea {
      border: none;
      outline: 0;
      padding: 2px;
      min-height: auto;
      field-sizing: content;
      height: fit-content;

      &:focus {
        border: none;
      }
    }
  }
`;
