// @ts-strict-ignore
import { isEmpty } from 'lodash/fp';
import { observer } from 'mobx-react';
import { ticketSelectors } from 'tests/models/components/ticket/ticket.selectors';

import { TicketType } from 'tests/models/components/ticket/ticket.types';

import { useTicketNetworkLoading } from 'mobx/hooks/useNetworkLoading';
import { useStores } from 'mobx/hooks/useStores';
import { useTicketCalls } from 'mobx/hooks/useTicketCalls';

import { FEATURES } from 'constants/features';

import { OperatorTicketUrgency } from 'models/OperatorTicket';
import Ticket from 'models/Ticket';

import { TicketTypeCreationAllowance } from 'models/TicketTypes';

import { useVerticalOverflowDetector } from 'hooks/useVerticalOverflowDetector';

import CardStack from 'views/Widgets/CardStack';
import { TicketPatientCallsList } from 'views/Widgets/PatientCallsList';

import { TicketSectionId } from 'views/WorkQueue/WorkQueue.types';

import { usePatientModel } from 'components/Patient/usePatientModel';
import { OperatorTicketSummary } from 'components/Ticket/TicketRow/OperatorTicketSummary';
import { TicketCommunicationSection } from 'components/Ticket/TicketRow/TicketCommunicationSection/TicketComments/TicketCommunicationSection';
import TicketRowIcon, { TicketRowIconType } from 'components/Ticket/TicketRow/TicketRowIcon';
import { TicketSummaryButton } from 'components/Ticket/TicketRow/TicketSummaryButton/TicketSummaryButton';
import { useTicketToggle } from 'components/Ticket/TicketRow/useTicketToggle';
import useTicketOverviewContext from 'components/Ticket/TicketsContainers/useTicketOverviewContext';
import { TextProps } from 'components/UIkit/atoms/Text/Text';

import { OPERATOR_TICKETS_DESCRIPTION_MAX_LINES } from './shared';
import TicketCreatedBy from './TicketCreatedBy';
import TicketRenderer from './TicketRenderer';

export interface OperatorTicketRowProps {
  ticket: Ticket;
  className?: string;
  withPatientLink: boolean;
  ticketRefs?: (node: HTMLElement, ticketId: number) => void;
  ticketIndex: number;
  ticketSectionCurrentPage?: number;
  ticketSectionId: TicketSectionId;
}
const OperatorTicketRow = ({
  ticket,
  className,
  withPatientLink,
  ticketRefs,
  ticketIndex,
  ticketSectionCurrentPage = 0,
  ticketSectionId
}: OperatorTicketRowProps) => {
  const { ticketActions: Actions, tab, hideActionButtons } = useTicketOverviewContext();
  const { ref: descriptionRef, isActive } = useVerticalOverflowDetector();
  const { isExpanded, toggle } = useTicketToggle(
    ticket.id,
    false,
    ticketSectionCurrentPage,
    isActive,
    ticket.messagesCount > 0,
    tab
  );
  const { ticketTypesStore, ticketsStore, settingsStore } = useStores();
  const patient = usePatientModel(ticket.patientId);
  const { operatorTicket } = ticket;
  const { getOperatorTicketTypeById } = ticketTypesStore;
  const isActionInProgress = useTicketNetworkLoading(ticket.id);
  const { calls, isLoadingCalls } = useTicketCalls(ticket, isExpanded);
  const ticketSelected = ticketsStore.ticketsBulkActionSet.has(ticket.id);
  const [subTypeId] = operatorTicket.subTicketTypeIds;
  const subTypeNode = getOperatorTicketTypeById(subTypeId);
  const showTicketMessages =
    settingsStore.hasFeature(FEATURES.PATIENT_MESSAGING) &&
    subTypeNode?.displaySettings?.includes(TicketTypeCreationAllowance.MESSAGING);

  const getSummary = () => {
    const descriptionContainerOptionalProps: {} | Pick<TextProps, 'maxNumberOfLines'> = isExpanded
      ? {}
      : { maxNumberOfLines: OPERATOR_TICKETS_DESCRIPTION_MAX_LINES };

    const isLightweightPathwaysQuestionsAnswersVisible =
      settingsStore.hasFeature(FEATURES.LIGHTWEIGHT_PATHWAYS) &&
      !isEmpty(ticket.operatorTicket?.pathwayIds);

    return (
      <div>
        <OperatorTicketSummary
          ticket={ticket}
          descriptionContainerOptions={descriptionContainerOptionalProps}
          subTypeNode={subTypeNode}
          isExpanded={isExpanded}
          descriptionRef={descriptionRef}
        />

        <TicketSummaryButton
          onClick={toggle}
          isExpanded={isExpanded}
          isShowMoreLessTextVisible={!ticket.newMessagesCount}
          ticketId={ticket.id}
          items={{
            draft: ticket.draftCallCount,
            comment: ticket.commentCount,
            call: ticket.callCount,
            callAttempt: ticket.callAttemptsCount,
            pathway: isLightweightPathwaysQuestionsAnswersVisible
              ? ticket.operatorTicket?.pathwayIds?.length
              : 0,
            newMessage: ticket.newMessagesCount,
            message: ticket.messagesCount
          }}
        />
      </div>
    );
  };

  return (
    <CardStack
      isSelected={ticketSelected}
      isOpen={isExpanded}
      classes={{
        root: className,
        closed: 'report-row-closed'
      }}
      numberOfStackedItems={ticket.callsAndDraftsCount}
    >
      <TicketRenderer
        testHook={ticketSelectors.container(ticket.id, TicketType.Operator, ticketSectionId)}
        ticketRefsCallback={ticketRefs}
        ticketId={ticket.id}
        isLoading={isActionInProgress}
        icon={
          <TicketRowIcon
            type={
              operatorTicket.urgency === OperatorTicketUrgency.High
                ? TicketRowIconType.OPERATOR_HIGH
                : TicketRowIconType.OPERATOR_STANDARD
            }
          />
        }
        classes={{
          container: 'no-border'
        }}
        actions={
          <Actions
            ticket={ticket}
            ticketIndex={ticketIndex}
            ticketSectionCurrentPage={ticketSectionCurrentPage}
          />
        }
        summary={getSummary()}
        patient={patient}
        createdByText={<TicketCreatedBy ticket={ticket} />}
        withPatientLink={withPatientLink}
      >
        {isExpanded && (
          <TicketCommunicationSection
            ticket={ticket}
            ticketIndex={ticketIndex}
            ticketSectionCurrentPage={ticketSectionCurrentPage}
            showMessages={showTicketMessages}
          />
        )}
      </TicketRenderer>
      {TicketPatientCallsList({
        calls,
        patient,
        isLoadingCalls,
        ticket,
        hideActionButtons
      })}
    </CardStack>
  );
};

export default observer(OperatorTicketRow);
