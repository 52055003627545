import { FC } from 'react';

import { Box, css, styled } from '@mui/material';
import { ticketSelectors } from 'tests/models/components/ticket/ticket.selectors';

import { TicketNewMessagesExpander } from 'components/Ticket/TicketRow/TicketSummaryButton/TicketNewMessagesExpander';

import { TicketSummaryItemType } from 'components/Ticket/TicketRow/TicketSummaryButton/TicketSummaryButton.types';
import { getSummaryItemDisplayName } from 'components/Ticket/TicketRow/TicketSummaryButton/TicketSummaryButton.utils';

import { TextButton } from 'components/UIkit/atoms/Button';
import { Text } from 'components/UIkit/atoms/Text';

interface Props {
  items?: Partial<Record<TicketSummaryItemType, number>>;
  onClick: () => void;
  isExpanded: boolean;
  order?: TicketSummaryItemType[];
  isShowMoreLessTextVisible?: boolean;
  ticketId: number;
}

export const TicketSummaryButton: FC<Props> = ({
  items = {},
  onClick,
  isExpanded,
  order = ['newMessage', 'message', 'pathway', 'report', 'comment', 'draft', 'callAttempt', 'call'],
  isShowMoreLessTextVisible = true,
  ticketId
}) => {
  const hasAtLeastOneItem = Object.values(items).some((item) => item > 0);
  const hasNewMessages = items.newMessage && items.newMessage > 0;

  return (
    <TextButton
      onClick={onClick}
      variant="secondary"
      mt={16}
      testHook={ticketSelectors.expandButton(ticketId)}
    >
      <StyledContainer>
        {isShowMoreLessTextVisible && (
          <Text variant="body2" display="flex" width="70px" color="dark">
            {isExpanded ? 'show less' : 'show more'}
            {hasAtLeastOneItem ? ':' : ''}
          </Text>
        )}

        {order.map((ticketSummaryItemType) => {
          const count = items[ticketSummaryItemType] || 0;

          if (count && count > 0) {
            if (hasNewMessages && ticketSummaryItemType === 'message') {
              return null;
            }

            return (
              <StyledItem key={ticketSummaryItemType}>
                {ticketSummaryItemType === 'newMessage' ? (
                  <TicketNewMessagesExpander count={count} />
                ) : (
                  getSummaryItemDisplayName({ count, ticketSummaryItemType })[ticketSummaryItemType]
                )}
              </StyledItem>
            );
          }

          return null;
        })}
      </StyledContainer>
    </TextButton>
  );
};

const StyledContainer = styled(Box)(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    height: 24px;

    &:hover {
      .new-messages {
        opacity: 1;

        span {
          color: ${theme.palette.natural.white};
        }
      }
    }
  `
);

const StyledItem = styled('span')(
  ({ theme }) => css`
    position: relative;

    &:not(:first-of-type) {
      margin-left: ${theme.spacing(12)};
    }

    &:not(:last-of-type) {
      padding-right: ${theme.spacing(12)};

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        height: 20px;
        width: 1px;
        background-color: ${theme.palette.natural.border};
      }
    }
  `
);
