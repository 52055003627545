// @ts-strict-ignore
import { FC, useMemo, useState } from 'react';

import { CLINICIAN_EDIT_CREATE_ERRORS, ErrorName } from 'errors';

import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { observer } from 'mobx-react';
import { useForm } from 'react-hook-form';
import { Col, Row } from 'reactstrap';

import { useStores } from 'mobx/hooks/useStores';

import { PermissionService } from 'services/PermissionService';

import { UserType, useUserTypeOptions } from 'utils/AccountUtils';
import { isChildDepartment } from 'utils/filtersUtils';
import { showToast } from 'utils/UserMessageUtils';
import { isValidName } from 'utils/ValidationUtils';
import * as ValidationUtils from 'utils/ValidationUtils';

import { NO_CREDENTIALS_ID } from 'constants/clinicanCredentials.const';
import { FEATURES } from 'constants/features';

import Department from 'models/Department';
import Doctor from 'models/Doctor';

import { useClinicianCredentialsOptions } from 'hooks/useClinicianCredentials';

import ChangePasswordModal from 'views/Modals/ChangePasswordModal';
import ResettableInput from 'views/Widgets/ResettableInput';
import { RHFStyledInput } from 'views/Widgets/StyledInput';
import StyledPhoneInput from 'views/Widgets/StyledPhoneInput';
import { ISelectOption } from 'views/Widgets/StyledSelect';

import { FormErrors } from 'components/Forms';

import { Separator } from 'components/Separator';
import { FormAutocomplete, FormMultiAutocomplete } from 'components/UIkit/atoms/Dropdown';
import { FormModal } from 'components/UIkit/atoms/Modal/FormModal';
import { ModalAction } from 'components/UIkit/atoms/Modal/Modal';

import './AccountModal.scss';

export interface AccountModalProps {
  isOpen: boolean;
  doctor?: Doctor;
  onCancel: () => void;
  onSaved: () => void;
  secondaryAction?: ModalAction;
}

interface AccountModalForm {
  firstName: string;
  lastName: string;
  credential: ISelectOption<number>;
  email: string;
  userType: ISelectOption<UserType>;
  phone: string;
  countryCode: string;
  selectedTeams: ISelectOption<number>[];
}

const AccountModal: FC<AccountModalProps> = (props) => {
  const { userStore, cliniciansStore, departmentsStore, constantsStore, settingsStore } =
    useStores();
  const [changePasswordOpen, setChangePasswordOpen] = useState(false);
  const [formError, setFormError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const credentialById = constantsStore.getClinicianCredentialById(props.doctor?.credentialId);
  const userTypeOptions = useUserTypeOptions();
  const isEditingOwnUser = userStore.currentClinicianId === props.doctor?.id;
  const clinicianCredentialOptions = useClinicianCredentialsOptions();
  const isPhoneRequired = !settingsStore.hasFeature(FEATURES.SSO_REGISTRATION);

  const credential = credentialById
    ? { value: credentialById?.id, label: credentialById?.text }
    : null;

  const teamOptions = useMemo(() => {
    return departmentsStore
      .getInstitutionDepartmentsAsArray()
      .filter(isChildDepartment)
      .map((department: Department) => ({
        value: department.id,
        label: department.name
      }));
  }, [departmentsStore]);

  const doctorDepartments = useMemo(
    () =>
      props.doctor?.departmentIds
        ? departmentsStore.departments.getItemByKeys(props.doctor.departmentIds)
        : [],
    [departmentsStore, props.doctor]
  );

  const selectedTeams = useMemo(
    () =>
      teamOptions.filter((option) =>
        doctorDepartments.some((docDepartment) => docDepartment.id === option.value)
      ),
    [teamOptions, doctorDepartments]
  );

  const methods = useForm<AccountModalForm>({
    shouldUnregister: false
  });

  const [firstName, lastName] = methods.watch(['firstName', 'lastName']);
  const fullName = `${firstName || ''} ${lastName || ''}`;
  const canResetPassword = isEditingOwnUser || userStore.isManager;
  const isEditedUserAdmin = !props.doctor ? false : PermissionService.isAdmin(props.doctor);

  const toggleChangePassword = () => {
    setChangePasswordOpen(!changePasswordOpen);
  };

  const handlePasswordChangeSaved = (oldPass: string, newPass: string) => {
    cliniciansStore.changePassword(props.doctor.id, oldPass, newPass).then(() => {
      showToast({ message: 'Password Changed' });
      setChangePasswordOpen(false);
    });
  };

  const handleSaveClicked = async (accountModalForm: AccountModalForm) => {
    setFormError('');
    setIsLoading(true);

    const credentialId =
      (accountModalForm.credential?.value !== NO_CREDENTIALS_ID &&
        accountModalForm.credential?.value) ||
      null;
    try {
      await userStore.updateDoctorAccount(
        props.doctor.id,
        accountModalForm.firstName.trim(),
        accountModalForm.lastName.trim(),
        credentialId,
        accountModalForm.email,
        accountModalForm.phone ? accountModalForm.countryCode + accountModalForm.phone : null,
        accountModalForm.userType.value,
        accountModalForm.selectedTeams?.map((option) => option.value)
      );

      props.onSaved();
      showToast({ message: 'Clinician updated successfully' });
    } catch (error) {
      setIsLoading(false);

      if (CLINICIAN_EDIT_CREATE_ERRORS.includes(error.name)) {
        setFormError(error?.ui?.title || 'Something went wrong');
      } else if (error.name === ErrorName.ThoughtSpotNewUser) {
        props.onSaved();
        throw error;
      } else {
        throw error;
      }
    }
  };

  const showDeactivateButton = !isEditingOwnUser && props.secondaryAction.isVisible;

  let phoneNumber;

  if (props.doctor?.phone) {
    phoneNumber = parsePhoneNumberFromString(props.doctor.phone);
  }

  const accountModalDefaultValues = {
    firstName: props.doctor?.firstName,
    lastName: props.doctor?.lastName,
    credential,
    email: props.doctor?.email,
    phone: phoneNumber ? String(phoneNumber.nationalNumber) : '',
    countryCode: phoneNumber ? `+${phoneNumber.countryCallingCode}` : '+1',
    userType: userTypeOptions.find((option) => option.value === props.doctor?.role),
    selectedTeams: selectedTeams
  };

  return (
    <div>
      <ChangePasswordModal
        isOpen={changePasswordOpen}
        onCancel={toggleChangePassword}
        onSaved={handlePasswordChangeSaved}
        shouldInputCurrentPass={isEditingOwnUser}
        clinicianId={props.doctor?.id}
      />

      <FormModal
        defaultValues={accountModalDefaultValues}
        methods={methods}
        isOpen={props.isOpen}
        isInternalModalOpen={changePasswordOpen}
        title={isEditingOwnUser ? 'My Account' : 'User: ' + fullName}
        confirmActions={[
          {
            onClick: methods.handleSubmit(handleSaveClicked),
            text: isLoading ? 'Saving...' : 'Save',
            disabled: isLoading,
            testHook: 'submit-btn'
          }
        ]}
        closeAction={{
          onClick: () => props.onCancel(),
          disabled: false
        }}
        secondaryAction={{
          isVisible: showDeactivateButton,
          onClick: props.secondaryAction?.onClick,
          text: props.secondaryAction?.text,
          disabled: props.secondaryAction?.disabled || isLoading,
          type: 'button'
        }}
        resetDataAfterClose={() => {
          setIsLoading(false);
          setFormError('');
        }}
      >
        <div className="account-details-container">
          <Row>
            <Col xs={12} sm={5} lg={5}>
              <RHFStyledInput
                label="First Name"
                name="firstName"
                register={methods.register}
                isRequired
                error={Boolean(methods.formState.errors.firstName)}
                validate={isValidName}
                testHook="firstName"
              />
            </Col>
            <Col xs={12} sm={5} lg={5} className="pl-1">
              <RHFStyledInput
                label="Last Name"
                name="lastName"
                isRequired
                register={methods.register}
                error={Boolean(methods.formState.errors.lastName)}
                validate={isValidName}
                testHook="lastName"
              />
            </Col>
            <Col xs={12} sm={2} lg={2} className="pl-1">
              <FormAutocomplete
                label="Credentials"
                name="credential"
                sortAlphabetically={false}
                options={clinicianCredentialOptions}
              />
            </Col>
          </Row>
          <Separator />
          <Row>
            <Col xs={12} sm={6} lg={6}>
              <StyledPhoneInput
                disabled={false}
                label="Phone"
                isRequired={isPhoneRequired}
                error={Boolean(
                  methods.formState.errors.phone || methods.formState.errors.countryCode
                )}
              />
            </Col>
            <Col xs={12} sm={6} lg={6}>
              <RHFStyledInput
                type="email"
                label="Email"
                register={methods.register}
                validate={(v: string) => ValidationUtils.isEmail(v)}
                name="email"
                error={Boolean(methods.formState.errors.email)}
                testHook="email"
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={6} lg={6}>
              <FormAutocomplete
                name="userType"
                label="Type"
                isRequired
                isClearable={false}
                isDisabled={isEditingOwnUser || isEditedUserAdmin}
                options={userTypeOptions}
                testHook="userType"
                labelTooltip="'Manager' users may access the Practice Manager, where they can manage users,
                                     care teams, providers, and locations.
                                     In addition, they can edit Shared Triage Rules for the entire practice.
                                     They cannot reset passwords. Only 'Admin' users may do so."
              />
            </Col>
            <Col xs={12} sm={6} lg={6}>
              <FormMultiAutocomplete
                label="Care Team Assignment (Optional) "
                placeholder="Select Care Team Assignment..."
                name="selectedTeams"
                options={teamOptions}
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={12} sm={6} lg={6}>
              <ResettableInput
                value="*********"
                isEditingEnabled={!canResetPassword}
                disabled={!canResetPassword}
                onResetClicked={() => setChangePasswordOpen(true)}
                label="Password"
              />
            </Col>
          </Row>
        </div>
        <FormErrors errors={formError ? [formError] : null} />
      </FormModal>
    </div>
  );
};

export default observer(AccountModal);
