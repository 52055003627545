// @ts-strict-ignore

import { isEmpty } from 'lodash/fp';

import { observer } from 'mobx-react';

import { ticketSelectors } from 'tests/models/components/ticket/ticket.selectors';

import { TicketType } from 'tests/models/components/ticket/ticket.types';

import { useTicketNetworkLoading } from 'mobx/hooks/useNetworkLoading';

import { useStores } from 'mobx/hooks/useStores';

import { useTicketCalls } from 'mobx/hooks/useTicketCalls';

import { FEATURES } from 'constants/features';

import Ticket from 'models/Ticket';

import { useVerticalOverflowDetector } from 'hooks/useVerticalOverflowDetector';

import CardStack from 'views/Widgets/CardStack';

import { TicketPatientCallsList } from 'views/Widgets/PatientCallsList';

import { TicketSectionId } from 'views/WorkQueue/WorkQueue.types';

import { usePatientModel } from 'components/Patient/usePatientModel';
import SymptomOperatorReportSummary from 'components/Ticket/TicketRow/SymptomOperatorReportSummary';
import { TicketCommunicationSection } from 'components/Ticket/TicketRow/TicketCommunicationSection/TicketComments/TicketCommunicationSection';
import TicketRowIcon from 'components/Ticket/TicketRow/TicketRowIcon';
import { TicketSummaryButton } from 'components/Ticket/TicketRow/TicketSummaryButton/TicketSummaryButton';
import { useTicketToggle } from 'components/Ticket/TicketRow/useTicketToggle';
import useTicketOverviewContext from 'components/Ticket/TicketsContainers/useTicketOverviewContext';

import { getSymptomIconAndAlertType } from './shared';
import TicketCreatedBy from './TicketCreatedBy';

import TicketRenderer from './TicketRenderer';

export interface OperatorTicketRowProps {
  ticket: Ticket;
  className?: string;
  withPatientLink: boolean;
  ticketRefsCallback?: (node: HTMLElement, ticketId: number) => void;
  ticketIndex: number;
  ticketSectionCurrentPage?: number;
  ticketSectionId: TicketSectionId;
}

const SymptomOperatorTicketRow = observer(
  ({
    ticket,
    className,
    withPatientLink,
    ticketRefsCallback,
    ticketIndex,
    ticketSectionCurrentPage = 0,
    ticketSectionId
  }: OperatorTicketRowProps) => {
    const { ref: descriptionRef, isActive } = useVerticalOverflowDetector();
    const { ticketActions: Actions, tab, hideActionButtons } = useTicketOverviewContext();
    const { isExpanded, toggle } = useTicketToggle(
      ticket.id,
      false,
      ticketSectionCurrentPage,
      isActive,
      ticket.messagesCount > 0,
      tab
    );
    const { ticketsStore, settingsStore } = useStores();

    const { operatorTicket } = ticket;
    const patient = usePatientModel(ticket.patientId);
    const isActionInProgress = useTicketNetworkLoading(ticket.id);

    const { calls, isLoadingCalls } = useTicketCalls(ticket, isExpanded);

    const ticketSelected = ticketsStore.ticketsBulkActionSet.has(ticket.id);
    const isLightweightPathwaysQuestionsAnswersVisible =
      settingsStore.hasFeature(FEATURES.LIGHTWEIGHT_PATHWAYS) &&
      !isEmpty(ticket.operatorTicket?.pathwayIds);
    const iconType = getSymptomIconAndAlertType(operatorTicket.urgency);

    const summary = (
      <div>
        <SymptomOperatorReportSummary
          ticket={ticket}
          patient={patient}
          isExpanded={isExpanded}
          descriptionRef={descriptionRef}
        />

        <TicketSummaryButton
          onClick={toggle}
          isExpanded={isExpanded}
          ticketId={ticket.id}
          items={{
            draft: ticket.draftCallCount,
            comment: ticket.commentCount,
            call: ticket.callCount,
            callAttempt: ticket.callAttemptsCount,
            pathway: isLightweightPathwaysQuestionsAnswersVisible
              ? ticket.operatorTicket?.pathwayIds?.length
              : 0
          }}
        />
      </div>
    );

    return (
      <CardStack
        isSelected={ticketSelected}
        isOpen={isExpanded}
        classes={{
          root: className,
          closed: 'report-row-closed'
        }}
        numberOfStackedItems={ticket.callsAndDraftsCount}
      >
        <TicketRenderer
          testHook={ticketSelectors.container(
            ticket.id,
            TicketType.SymptomOperator,
            ticketSectionId
          )}
          ticketId={ticket.id}
          isLoading={isActionInProgress}
          ticketRefsCallback={ticketRefsCallback}
          icon={<TicketRowIcon type={iconType} />}
          summary={summary}
          classes={{
            container: 'no-border',
            body: 'wide-summary'
          }}
          actions={
            <Actions
              ticket={ticket}
              ticketIndex={ticketIndex}
              ticketSectionCurrentPage={ticketSectionCurrentPage}
            />
          }
          patient={patient}
          createdByText={<TicketCreatedBy ticket={ticket} />}
          withPatientLink={withPatientLink}
        >
          {isExpanded && (
            <TicketCommunicationSection
              ticket={ticket}
              ticketIndex={ticketIndex}
              ticketSectionCurrentPage={ticketSectionCurrentPage}
            />
          )}
        </TicketRenderer>
        {TicketPatientCallsList({
          calls,
          patient,
          isLoadingCalls,
          ticket,
          hideActionButtons
        })}
      </CardStack>
    );
  }
);

export default SymptomOperatorTicketRow;
